const listing = {};

listing.list = {
    companyId: {
        type:        'text',
        title:       'f10',
        width:       '5',
        typeSearch:  'companyId',
        sort_filter: 3,
        filter:      'SearchV2',
    },

    partner: {
        type:   'text',
        title:  'f18',
        width:  '5',
        filter: 'SearchV2',

    },

    parnerCompanyProfit: {
        type:  'text',
        title: 'parnerCompanyProfit',
        width: '2',
        sort:  true,
    },

};

export { listing };
