const menuItems = [
    {
        title: 'mn1',
        key:   'users',
        // roles: [  'manager',  'admin' ],
    },
    {
        title: 'mn2',
        key:   'company',
        //roles: [ 'manager', 'admin' ],
    },
    {
        title: 'mn3',
        key:   'phone',
        //roles: [ 'manager', 'admin' ],
    },
    {
        title: 'mn4',
        key:   'calls',
        //roles: [ 'client', 'manager', 'admin' ],
    },
    {
        title: 'mn5',
        key:   'serviceCompany',
        //roles: [ 'client', 'manager', 'admin' ],
    },
    {
        title: 'mn6',
        key:   'postman',
        //roles: [ 'admin' ],
    },
    {
        title: 'mn7',
        key:   'analytics',
        //roles: [ 'manager', 'admin' ],
    },
    {
        title: 'mn8',
        key:   'serviceBalance',
        //roles: [ 'client', 'manager', 'admin' ],

    },
    {
        title: 'mn10',
        key:   'profit/1',
        //roles: [ 'manager', 'admin' ],
    },
    {
        title: 'mn9',
        key:   'setting',
        //roles: [ 'client', 'manager', 'admin' ],
    },
    {
        title: 'mn11',
        key:   'tech',
        //roles: [ 'admin' ],
    },
    {
        title: 'mn12',
        key:   'atc',
        //roles: [ 'admin' ],
    },
    {
        title: 'mn15',
        key:   'partners',
        //roles: [ 'admin' ],
    },
    {
        title: 'mn13',
        key:   'roles',
        //roles: [ 'admin' ],
    },
];


export const list = {
    menuItems: menuItems,
};
