/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ListConstructorJSX from './indexJSX';
import { api } from '../../../Api';

const selectState = (state) => ({

    translate: state.swapi.translate,
    lang:      state.swapi.lang,
});
function ListConstructor(props) {
    const state = useSelector(selectState);

    const { fields, moduleName, isShowAddBtn } = props;
    const [ filteredFields, setFilteredFields ] = useState({});
    const translate = state.translate;
    const lang = state.lang;

    useEffect(() => {
        const getData = async () => {
            const data = await api.postData({ moduleName: moduleName }, '/getUserFields');
            if (data?.length > 0) {
                const _fields = {};
                for (const item of Object.keys(fields)) {
                    if (data.includes(item)) {
                        _fields[ item ] = fields[ item ];
                        _fields[ item ].title = translate[ _fields[ item ].title ] || _fields[ item ].title;
                    }
                }
                setFilteredFields(_fields);
            }
        };
        getData();
    }, [ fields, moduleName, lang ]);

    return (
        <>
            { Object.keys(filteredFields).length > 0 && (
                <ListConstructorJSX
                    { ...props }
                    fields = { filteredFields }
                    isShowAddBtn = { isShowAddBtn && filteredFields.delete }
                />
            )}
        </>
    );
}


export default ListConstructor;
