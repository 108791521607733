import React, { useState, useEffect } from 'react';
import { Menu, MenuItem, IconButton, Divider, Box } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Save, Cancel } from '@mui/icons-material';
import  { deleteItem  }  from '../ListConstructor/utils';
import StylesForm from '../../../theme/styles/form.module.scss';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const selectState = (state) => ({
    translate: state.swapi.translate,
});

export const BtnAll = (props) => {
    const { item, componentName, setHidden, urlApi, userData, type, edit,
        setIsSave, setEdit, setIsAdd, field, changeStatus } = props;
    const [ anchorEl, setAnchorEl ] = useState(null);
    const [ data, setData ] = useState(null);

    const state = useSelector(selectState);
    const translate = state.translate;

    useEffect(() => {
        const _data = {
            id:        item._id,
            setHidden: setHidden,
            urlApi:    urlApi,
            userData:  userData,
            type:      type,
        };
        setData({ ..._data });
    }, [ item._id, urlApi, userData, type ]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const saveStyles = {
        color: '#008000',
    };

    const cancelStyles = {
        color: 'rgb(25, 118, 210)',
    };
    const hrefEdit = '/' + componentName + '/' + item._id;
    let href = '';
    const btnJSX = field.values && field.values.length > 0 ? field.values.map((el) => {
        let renderData = '';
        let h = hrefEdit;
        if (el === 'edit') {
            h = hrefEdit;
        }
        if (el === 'link') {
            href = item.link && item.link.includes('http') > -1 ? item.link : '/' + item.link;
            h = href;
        }

        if (el === 'status') {
            renderData = (
                <div
                    className = { `${StylesForm.button} ${ !item[ el ] ? StylesForm.warning : StylesForm.error }` }
                    onClick = { () => {
                        changeStatus(!item[ el ], el);
                    } }>
                    { !item[ el ] ? 'enable' : 'disable' }
                </div>
            );
        }

        if (el !== 'delete' && el) {
            renderData = (
                <Link
                    className = { `${StylesForm.button}` }
                    target = '_blank'
                    to = { h ? h : '#' }>
                    {el}
                </Link>
            );
        }
        if (el === 'delete') {
            renderData = (
                <div
                    className = { `${StylesForm.btnOutline}` }
                    onClick = { () => {
                        deleteItem(data, translate);
                        handleClose();
                        setIsAdd(false);
                    } }>
                    <svg
                        fill = 'none'
                        height = '14'
                        viewBox = '0 0 12 14'
                        width = '12'
                        xmlns = 'http://www.w3.org/2000/svg'>
                        <path
                            clipRule = 'evenodd'
                            d = 'M8.83983 2.33331L8.66667 2.33331C8.66667 1.22874 7.77124 0.333313 6.66667 0.333313H5.33333C4.22876 0.333313 3.33333 1.22874 3.33333 2.33331L2.89552 2.33331L2.66667 2.33331L2.66588 2.33331L0.666667 2.33331C0.298477 2.33331 0 2.63179 0 2.99998C0 3.36817 0.298477 3.66665 0.666667 3.66665L1.33333 3.66665L1.33333 11.6666C1.33333 12.7712 2.22877 13.6666 3.33333 13.6666H8.66667C9.77124 13.6666 10.6667 12.7712 10.6667 11.6666L10.6667 3.66665L11.3333 3.66665C11.7015 3.66665 12 3.36817 12 2.99998C12 2.63179 11.7015 2.33331 11.3333 2.33331L9.33456 2.33331L9.33333 2.33331L8.83983 2.33331ZM9.33333 3.66665H8L7.33333 3.66665L4.66667 3.66665L4 3.66665L2.66667 3.66665L2.66667 4.99998L2.66667 11.6666C2.66667 12.0348 2.96514 12.3333 3.33333 12.3333H8.66667C9.03486 12.3333 9.33333 12.0348 9.33333 11.6666L9.33333 4.99998V3.66665ZM4.66667 2.33331H5.33333H6H6.66667H7.33333C7.33333 1.96512 7.03486 1.66665 6.66667 1.66665L5.33333 1.66665C4.96514 1.66665 4.66667 1.96512 4.66667 2.33331ZM4.66667 4.99998C5.03486 4.99998 5.33333 5.29846 5.33333 5.66665V10.3333C5.33333 10.7015 5.03486 11 4.66667 11C4.29848 11 4 10.7015 4 10.3333V5.66665C4 5.29846 4.29848 4.99998 4.66667 4.99998ZM8 5.66665C8 5.29846 7.70152 4.99998 7.33333 4.99998C6.96514 4.99998 6.66667 5.29846 6.66667 5.66665V10.3333C6.66667 10.7015 6.96514 11 7.33333 11C7.70152 11 8 10.7015 8 10.3333V5.66665Z'
                            fill = '#EE3434'
                            fillRule = 'evenodd'
                        />
                    </svg>
                </div>
            );
        }
        if (el === 'rowEdit') {
            renderData = (
                <div
                    className = { `${StylesForm.btnOutline}` }
                    onClick = { () => {
                        setEdit(item._id);
                    } }>
                    <svg
                        fill = 'none'
                        height = '14'
                        viewBox = '0 0 14 14'
                        width = '14'
                        xmlns = 'http://www.w3.org/2000/svg'>
                        <path
                            clipRule = 'evenodd'
                            d = 'M9.55714 0.865992L0.917223 9.50591C-0.0731528 10.4963 -0.0731593 12.102 0.917222 13.0924C1.9076 14.0828 3.51332 14.0828 4.5037 13.0924L13.1436 4.45247C13.7167 3.87936 13.7167 2.95016 13.1436 2.37704L11.6326 0.865992C11.0595 0.292879 10.1303 0.29288 9.55714 0.865992ZM8.02779 4.28096L10.4999 1.8088C10.5524 1.75639 10.6373 1.75639 10.6898 1.8088L12.2008 3.31985C12.2532 3.37227 12.2532 3.45725 12.2008 3.50966L9.72865 5.98182L8.02779 4.28096ZM7.08499 5.22376L1.86003 10.4487C1.39035 10.9184 1.39035 11.6799 1.86003 12.1496C2.32971 12.6193 3.09121 12.6193 3.56089 12.1496L8.78584 6.92462L7.08499 5.22376Z'
                            fill = '#272727'
                            fillRule = 'evenodd'
                        />
                    </svg>
                </div>
            );
        }

        return (
            <React.Fragment key = { el }>
                { renderData }
            </React.Fragment>
        );
    }) : (
        <>
            <IconButton
                aria-controls = 'btn-menu'
                aria-haspopup = 'true'
                aria-label = 'more'
                size = 'small'
                onClick = { handleClick }>
                <MoreVertIcon />
            </IconButton>
            <Menu
                keepMounted
                anchorEl = { anchorEl }
                id = 'btn-menu'
                open = { Boolean(anchorEl) }
                onClose = { handleClose }>

                <MenuItem
                    component = 'a'
                    href = { hrefEdit }
                    target = '_blank'>Edit
                </MenuItem>
                <MenuItem
                    component = 'a'
                    href = { href }
                    target = '_blank'>Link
                </MenuItem>
                <Divider sx = {{ background: '#e3eefa', opacity: '1' }}></Divider>
                <MenuItem onClick = { () => {
                    deleteItem(data, setHidden);
                    handleClose();
                    setIsAdd(false);
                } }>Delete
                </MenuItem>
            </Menu>
        </>
    );

    return  (
        <>

            { edit === item._id ? (
                <>
                    <Box>
                        <IconButton
                            size = 'small'
                            sx = {{ padding: 0 }}
                            onClick = { () => setIsSave(true) }>
                            <Save

                                style = { saveStyles }
                            />
                        </IconButton>
                    </Box>
                    <Box>
                        <IconButton
                            size = 'small'
                            sx = {{ padding: 0 }}
                            onClick = { () => {
                                setEdit(null);
                                if (typeof setIsAdd === 'function') {
                                    setIsAdd(false);
                                }
                            } }>
                            <Cancel style = { cancelStyles } />
                        </IconButton>
                    </Box>
                </>
            ) : (<div className = { StylesForm.btnsCol }>{btnJSX}</div>)

            }
        </>
    );
};
