const listing = {
    companyId: {
        type:        'text',
        title:       'f10',
        width:       '5',
        typeSearch:  'companyId',
        //roles:       [ 'admin', 'manager' ],
        sort:        true,
        filter:      'SearchV2',
        sort_filter: 1,
        component:   'SearchV2',

    },

    amount: {
        type:  'text',
        title: 'f57',
        width: '10',
        sort:  true,
    },
    remainder: {
        type:  'text',
        title: 'f58',
        width: '10',
        sort:  true,
    },
    createdAt: {
        type:        'date',
        title:       'f30',
        width:       '10',
        sort:        true,
        filter:      'DateRangeField',
        sort_filter: 3,
    },
    comment: {
        type:   'text',
        title:  'f59',
        width:  '10',
        filter: 'Input',

    },
    delete: {
        type:   'delete',
        title:  'f9',
        values: [ 'delete'  ],
        width:  '1',
        //roles:  [ 'admin' ],
    },

};
export { listing };
