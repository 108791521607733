import Cookies from 'js-cookie';
import { apiActions } from '../bus/actions';
import { store } from '../init/store';
const sessionKey = 'sessionData';

// eslint-disable-next-line
const rEmail = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i; // eslint-disable-next-line

export const isEmail = (value) => {
    return rEmail.test(value);
};

export const getCookieObj = (key) => {
    const sessionData = Cookies.get(key);
    const storage = sessionData ? JSON.parse(sessionData) : {};

    return storage;
};

export const getCookieLang = () => {
    const sessionData = Cookies.get('language');
    const storage = sessionData ? sessionData : 'en';

    return storage;
};
export const getCompanyActive = () => {
    const sessionData = Cookies.get('companyActive');
    const storage = sessionData ? sessionData : '';

    return storage;
};
export const setCookieLang = (code) => {
    Cookies.set('language', code, { expires: 365 });

    return null;
};

export const setCompanyActive = (code) => {
    Cookies.set('companyActive', code, { expires: 365 });

    return null;
};
export const getUserData = () => {
    let result = null;

    const storage = getCookieObj(sessionKey);
    if (storage && storage.token) {
        result = storage;
    }

    return result;
};

export const saveSession = (data = {}) => {
    if (data.company_id && data.company_id.length > 0) {
        for (const c of data.company_id) {
            delete c.service;
        }
    }
    Cookies.set(sessionKey, JSON.stringify(data), { expires: 2 });

    return null;
};

export const removeSession = () => {
    Cookies.remove(sessionKey);
};


export const extraLogout = () => {
    removeSession();
    store.dispatch(apiActions.setIsLogged(false));
    store.dispatch(apiActions.setUserData(null));
    store.dispatch(apiActions.setCompany({}));
    const url = '/login';
    window.location.pathname = url;
};

export const isLogged = () => {
    let result = false;

    const storage = getCookieObj(sessionKey);
    if (storage && storage.token) {
        result = true;
    }

    return result;
};

export const MorNames = {
    1: 'rsb.pbxvoip.cc',
    2: 'didvoice.com',
    3: 'cc.pbxvoip.cc',
    4: 'dgvoice.me',
    5: 'sip-connecta.tel',
};

export const AtsNames = {
    1: 'АТС 1',
    2: 'АТС 2',
};

export const titlesAdd = {
    'admin/user':    'ad1',
    'admin/company': 'ad2',
    'admin/phone':   'ad3',
    'admin/postman': 'ad4',
};

export const titlesEdit = {
    'admin/user':    'ed1',
    'admin/company': 'ed2',
    'admin/phone':   'ed3',
    'admin/postman': 'ed4',
};
