const listing = {};

listing.list = {
    name: {
        type:        'text',
        title:       'f10',
        width:       '10',
        filter:      'Input',
        link:        '/admin/company/',
        component:   'Input',
        role_edit:   [ 'admin' ],
        sort_filter: 1,
        isSearch:    true,
    },
    mor_type: {
        type:        'custom',
        title:       'f11',
        width:       '5',
        filter:      'Select',
        component:   'Select',
        sort_filter: 2,
    },
    atc_type: {
        type:        'custom',
        title:       'f12',
        width:       '5',
        filter:      'Select',
        component:   'Select',
        sort_filter: 3,
    },
    // type_compamy: {
    //     type:      'text',
    //     title:     'Тип',
    //     width:     '5',
    //     filter:    'Select',
    //     component: 'Select',
    //     roles:     [ 'admin' ],

    // },
    managerId: {
        type:        'text',
        title:       'f13',
        width:       '10',
        component:   'SearchV2',
        filter:      'SearchV2',
        sort_filter: 4,
    },

    credit: {
        type:      'text',
        title:     'f14',
        width:     '3',
        component: 'Input',
    },
    mor_ids: {
        type:  'custom',
        title: 'f15',
        width: '10',
    },

    next_pay: {
        type:  'text',
        title: 'f16',
        width: '5',
    },
    balance: {
        type:      'custom',
        title:     'f17',
        width:     '10',
        sort:      true,
        role_edit: [ 'admin' ],
    },
    partner: {
        type:        'text',
        title:       'f18',
        width:       '10',
        component:   'SearchV2',
        filter:      'SearchV2',
        // roles:       [ 'admin' ],
        sort_filter: 5,
    },
    partnerStartDate: {
        type:      'date',
        title:     'f38',
        width:     '10',
        sort:      true,
        component: 'DateField',
    },
    partnerPercent: {
        type:      'text',
        title:     'f6',
        width:     '5',
        component: 'Input',
        //roles:     [ 'admin' ],
        role_edit: [ 'admin' ],
    },
    partnerPercentTrafic: {
        type:      'text',
        title:     'f6_',
        width:     '5',
        component: 'Input',
        //roles:     [ 'admin' ],
        role_edit: [ 'admin' ],

    },
    atc_price_purchase: {
        type:      'text',
        title:     'f75',
        width:     '5',
        component: 'Input',
        //roles:     [ 'admin' ],
        role_edit: [ 'admin' ],

    },
    atc_price: {
        type:      'text',
        title:     'f76',
        width:     '5',
        component: 'Input',
        role_edit: [ 'admin' ],

    },
    status: {
        type:      'checkbox',
        title:     'f34',
        width:     '5',
        component: 'InputCheckbox',
        filter:    'InputCheckbox',
    },
    delete: {
        type:   'delete',
        title:  'f9',
        values: [ 'rowEdit' ],
        width:  '5',
    },
};

export { listing };
