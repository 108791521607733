import React, { useState } from 'react';
import { Snackbar, Alert } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faClone } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';


const selectState = (state) => ({
    translate: state.swapi.translate,
});

export const Text = (props) => {
    const { item, fieldKey, isCopy } = props;

    const [ isSnackbarOpen, setSnackbarOpen ] = useState(false);
    const state = useSelector(selectState);
    const translate = state.translate;

    const copyToClipboard = async (text) => {
        if (isCopy) {
            try {
                await navigator.clipboard.writeText(text);
                if (typeof setCallBackClickCopy === 'function') {
                    setCallBackClickCopy(text);
                }
                setSnackbarOpen(true);
            } catch (error) {
                console.error("Failed to copy text: ", error);
            }
        }
    };

    const val = typeof item[ fieldKey ] === 'number'
        ? item[ fieldKey ].toLocaleString('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 4,
        })
        : item[ fieldKey ];

    let text = '';

    if (val?.label) {
        text = val.label;
    } else if (typeof val === 'string') {
        text = val;
    }

    if (typeof text === 'string') {
        text = text.split(/\s+/).map((word) => {
            // Перекладаємо слово, якщо воно є у словнику translate
            return translate[ word ] ?? word;
        })
            .join(' ');
    }


    return (
        <>
            <span  onClick = { ()=> copyToClipboard(item[ fieldKey ])  }>

                {text}
                <br/>

                {isCopy ? (
                    <FontAwesomeIcon
                        icon = { faClone }
                        style = {{ color: "#1976d2" }}
                    />) : ''}
            </span>
            <Snackbar
                autoHideDuration = { 3000 }
                open = { isSnackbarOpen }
                onClose = { () => setSnackbarOpen(false) }>
                <Alert
                    severity = 'success'
                    variant = 'filled'>
                    Text copied to clipboard!
                </Alert>
            </Snackbar>
        </>

    );
};
