import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { api } from '../../../Api';
import StylesStat from '../../../theme/styles/stat.module.scss';

const selectState = (state) => ({
    translate: state.swapi.translate,
});

function AccountTopTotal(props) {
    const { filter, type } = props;
    const state = useSelector(selectState);
    const translate = state.translate;
    const [ totals, setTotals ] = useState({});
    const getTotal = async (filter) => {
        try {
            const response = await api.postData({ filterData: filter, type }, '/getTotal');
            if (response && response) {
                setTotals(response);
            }
        } catch (error) {
            console.error('Error fetching totals:', error);
        }
    };


    useEffect(() => {
        getTotal(filter);
    }, [ filter ]);


    return (
        <div
            className = { `${StylesStat.statCalls}` }
            style = {{ marginBottom: '30px' }}>
            { Object.keys(totals).map((key, index) => {
                const item = totals[ key ];

                return (
                    <div
                        className = { StylesStat.statCallsItem }
                        key = { index }>
                        <div className = { `${StylesStat.top}` }>
                            <div className = { `${StylesStat.name}` }>
                                {translate[ item.name ] || item.name}
                            </div>
                            <div className = { `${StylesStat.info}` }>
                                <img
                                    alt = ''
                                    src = '/icons/info.svg'
                                />
                            </div>
                        </div>
                        <div className = { `${StylesStat.data}` }>
                            <div className = { `${StylesStat.value}` }>
                                {item.value}  {/* Виведення значення з форматуванням */}
                            </div>
                            <div className = { `${StylesStat.dynamic} ${item.dynamic > 0 ? StylesStat.up : StylesStat.down}` }>
                                <img
                                    alt = ''
                                    src = { `/icons/d_${item.dynamic > 0 ? 'up' : 'down'}.svg` }
                                />
                                {`${item.dynamic > 0 ? '+' : ''}${item.dynamic}%`}
                            </div>
                        </div>
                    </div>
                );
            })}

        </div>

    );
}

export default AccountTopTotal;
