import React, { useEffect, useState } from 'react';
import ListConstructor from '../ListConstructor';
import { listing } from './listing';
import { useSelector } from 'react-redux';
import { setTraslate } from '../../../instruments/setTraslate';
import { api } from '../../../Api';

const selectState = (state) => ({
    userData:  state.swapi.userData,
    translate: state.swapi.translate,
});

function AccountUsers(props) {
    const state = useSelector(selectState);
    const translate = state.translate;
    const userData = state.userData;

    const [ list, setList ] = useState({ ...listing.list });
    const [ listSelect, setListSelect ] = useState(null);

    useEffect(()=>{
        const getRoles = async () => {
            const client = { value: 'client', label: translate.r_client };
            const data = await api.postData({ type: 'roles' }, '/getRolesNames');
            const listSelect = {
                role: [],
            };
            if (data?.length > 0) {
                for (const role of data) {
                    listSelect.role.push({ value: role, label: translate[ `r_${role}` ] || role });
                }
            } else {
                listSelect.role.push(client);
            }
            setListSelect(listSelect);
        };
        getRoles();
    }, [ ]);

    useEffect(()=>{
        const _list = setTraslate({ ...listing.list }, translate);
        setList(_list);
    }, [ translate, listing.list ]);

    const btns = [
        {
            component: 'WelcomeBtn',
            roles:     [ 'admin', 'manager', 'team_lead', 'manager_survisior' ],

        },
    ];

    return  (
        <>
            { listSelect?.role?.length > 0 && (
                <ListConstructor
                    { ...props }
                    btns = { btns }
                    fields = { list }
                    isShowAddBtn = { userData?.role === 'admin' }
                    limit = { 30 }
                    listSelect = { listSelect }
                    moduleName = { 'users' }
                    moduleTitle = { translate.mn1 }
                    sortDefault = 'createdAt'
                    sortOrderDefault = { false }
                    type = { 'user' }
                    urlApi = { '/userList' }
                />
            ) }
        </>
    );
}

export default AccountUsers;
