import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiActions } from '../../bus/actions';
import { removeSession } from '../../instruments';
import Styles from './user.module.scss';
import { setCompanyActive } from '../../instruments';

const selectState = (state) => ({
    company:   state.swapi.company,
    translate: state.swapi.translate,
});

function UserMenu(props) {
    const { userData } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const state = useSelector(selectState);
    const company = state.company;
    const translate = state.translate;
    const [ showMenu, setShowMenu ] = useState(false);

    const logout = () => {
        setShowMenu(false);
        removeSession();
        dispatch(apiActions.setIsLogged(false));
        dispatch(apiActions.setUserData(null));
        dispatch(apiActions.setCompany({}));
        const url = '/login';
        navigate(url);
    };

    return userData && (
        <div
            className = { `${Styles.wrap} ${ showMenu ? Styles.open : ''}` }
            onClick = { ()=>setShowMenu(!showMenu) }>
            <div
                className = { Styles.icon }>
                <img
                    alt = ''
                    src = { '/icons/Down.svg' }
                />
            </div>
            <div className = { Styles.info }>
                <div className = { Styles.user }>
                    <span className = { Styles.name }>
                        {userData.name}
                    </span>
                    { userData.company_id?.[ 0 ]?.name && (
                        <span className = { Styles.company }>
                            {company?.name || ''}
                        </span>
                    )}
                </div>
                <div className = { Styles.avatar }>
                    {userData.name?.slice(0, 2)}
                </div>
            </div>
            <div
                className = { `${Styles.menu} ${ showMenu ? Styles.open : ''}` }>
                <div className = { Styles.name }>
                    {userData.name}
                </div>
                <div className = { Styles.email }>
                    {userData.email}
                </div>
                <div className = { Styles.line } />
                { userData.company_id?.length > 0
                    && userData.company_id.map((el)=>{
                        return (
                            <div
                                className = { Styles.company }
                                key = { el._id }
                                onClick = { () => {
                                    dispatch(apiActions.setCompany(el));
                                    setCompanyActive(el._id);
                                } }>
                                {el.name}
                            </div>

                        );
                    })
                }
                <div
                    className = { Styles.logout }
                    onClick = { logout }>
                    { translate.so }
                </div>
            </div>
        </div>
    );
}

export default UserMenu;
