// eslint-disable-line
import React, { useEffect, useState  } from 'react';
import { NavLink } from 'react-router-dom';
import { list } from './menu_list';
import Styles from './menu.module.scss';
import { useSelector } from 'react-redux';
import Icons from '../Icons';
import { api } from '../../Api';

const selectState = (state) => ({
    userData:  state.swapi.userData,
    isLogged:  state.swapi.isLogged,
    translate: state.swapi.translate,
});

function Menu(props) {
    const { open } = props;
    const state = useSelector(selectState);
    const userData = state.userData;
    const translate = state.translate;
    const [ menu_list, setMenuList ] = useState([]);
    const [ active, setActive ] = useState('');

    useEffect(() => {
        const getData = async () => {
            const data = await api.postData({ type: 'roles' }, '/getUserModules');
            if (data?.length > 0 && list?.menuItems?.length > 0) {
                const menu = list?.menuItems.filter((item)=>data.includes(item.key));
                setMenuList(menu);
            }
        };

        getData();
    }, [ list, userData ]);

    const navList = menu_list.map((menu) => {
        let link = userData ? `/admin/${menu.key}` : '';
        if (menu.key === 'home') {
            link = '/';
        }

        const Icon = Icons[ menu.key ] || null;

        return (
            <NavLink
                className = { ({ isActive }) => `${Styles.menuItem} ${open ? Styles.open : ''}  ${isActive || active === menu.key ? Styles.active : ''} ` }
                key = { menu.key }
                to = { link }
                onClick = { ()=>setActive(menu.key) }>
                <span className = { Styles.m_icon }>
                    { Icon && (
                        <Icon className =  { Styles.m_icon }/>
                    ) }
                </span>
                <span className = { Styles.m_txt }>
                    { translate[ menu.title ] || menu.title }
                </span>
                <span className = { Styles.tooltip }>
                    { translate[ menu.title ] || menu.title }
                </span>
            </NavLink>
        );
    });

    return (
        <div className = { Styles.wrap }>
            { navList }
        </div>
    );
}
export default Menu;
