import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { book } from '../../../instruments';
import Atc from '../Atc';

export const AtcRouter = (props) => {
    return (
        <Routes>
            <Route
                exact
                element = {
                    <Atc
                        { ...props }
                    />
                }
                path = { book.admin.atc }
            />
        </Routes>
    );
};
