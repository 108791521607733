import { api } from '../../../Api';
import { confirmAlert } from 'react-confirm-alert';
import '../../../theme/styles/confirmAllert.css';

export const deleteItem = (data, translate) => {
    const { id, setHidden, urlApi, userData, type } = data;
    if (id === 'new') {
        setHidden(true);

        return;
    }
    confirmAlert({
        title:            translate.m1,
        message:          translate.m2,
        overlayClassName: 'confirmAlertOverlay',
        buttons:          [
            {
                label:   translate.m3,
                onClick: async () => {
                    const statusData = {
                        _id:      id,
                        userData: userData,
                        type:     type,
                    };

                    const result = await api.postData(statusData,  urlApi + '/delete/' + id);

                    if (result) {
                        setHidden(true);
                    }
                },
                className: 'confirm',
            },
            {
                label:     translate.m4,
                className: 'cancel',
            },
        ],
    });
};


export const action = async (props) => {
    const { data, id, type, urlApi, game, typePage } = props;
    if (type === 'save') {
        await api.postData({ data: data, game: game, type: typePage }, urlApi + '/save/' + id);
    } else {
        data.type = type;
        await api.postData({ data: data, type: typePage }, urlApi +  '/status/' + id);
    }
};
