import { useSelector } from 'react-redux';

export const templateToBalance = () => {
    const selectState = (state) => ({
        translate: state.swapi.translate,
    });

    const state = useSelector(selectState);
    const translate = state.translate;

    return [
        { label: translate.com1, value: 'com1' },
        { label: translate.com2, value: 'com2' },
        { label: translate.com3, value: 'com3' },
        { label: translate.com4, value: 'com4' },
        { label: translate.com5, value: 'com5' },
        { label: translate.com6, value: 'com6' },
        { label: translate.com7, value: 'com7' },

    ];
};
