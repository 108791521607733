import React, { useEffect, useState } from 'react';
import ListConstructor from '../ListConstructor';
import { listing } from './listing';
import { setTraslate } from '../../../instruments/setTraslate';
import { useSelector } from 'react-redux';
import AccountTopTotal from '../TopTotal';

const selectState = (state) => ({
    translate: state.swapi.translate,
});

function AccountAtc(props) {
    const state = useSelector(selectState);
    const translate = state.translate;
    const [ list, setList ] = useState({ ...listing.list });
    const [ filter, setFilter ] = useState({});

    const setCallBack =  (filter) => {
        setFilter(filter);
    };


    useEffect(() => {
        const _list = setTraslate({ ...listing.list }, translate);
        setList(_list);
    }, [ translate, listing.list ]);

    const listSelect = {
        atc_type: [
            {
                value: 1,
                label: 1,
            },
            {
                value: 2,
                label: 2,
            },
        ],
    };

    return (
        <>
            <AccountTopTotal
                filter = { filter }
                type = { 'atc' }
            />
            <ListConstructor
                { ...props }
                isShowAddBtn
                fields = { list }
                limit = { 30 }
                listSelect = { listSelect }
                moduleName = { 'atc' }
                setCallBack = { setCallBack }
                sortDefault = 'createdAt'
                sortOrderDefault = { false }
                type = { 'atc' }
                urlApi = { '/atc' }
            />
        </>
    );
}

export default AccountAtc;
