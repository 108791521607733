import React, { useEffect, useState } from 'react';
import ListConstructor from '../ListConstructor';
import { listing } from './listing';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Styles from '../../../theme/styles/pageHeader.module.scss';
import { setTraslate } from '../../../instruments/setTraslate';
import AccountTopTotal from '../TopTotal';

const selectState = (state) => ({
    userData:  state.swapi.userData,
    translate: state.swapi.translate,
});


function AccountAnalytics(props) {
    const state = useSelector(selectState);
    const translate = state.translate;
    const userData = state.userData;
    const [ list, setList ] = useState({ ...listing.list });
    const [ filter, setFilter ] = useState({ ...listing.list });

    useEffect(()=>{
        const _list = setTraslate({ ...listing.list }, translate);

        setList(_list);
    }, [ translate, listing.list ]);

    useEffect(()=>{
        const f = {
            createdAt: {
                from: moment().date(1)
                    .hour(0)
                    .minute(0)
                    .second(0),
                to: moment()
                    .hour(23)
                    .minute(0)
                    .second(0),
            },

        };
        if (userData.role === 'team_lead') {
            f.managerId = userData._id;
        }
        setFilter(f);
    }, [ ]);


    const setCallBack =  (filter) => {
        setFilter(filter);
    };

    return (
        <>
            <div className = { Styles.header }>
                <div className = { Styles.title }>
                    { translate.mn7 }
                </div>
            </div>
            <AccountTopTotal
                filter = { filter }
                type = { 'analytics' }
            />
            <ListConstructor
                { ...props }
                isDefaultFilter
                fields = { list }
                filterOutside = { filter }
                limit = { 30 }
                moduleName = { 'analytics' }
                setCallBack = { setCallBack }
                sortDefault = 'createdAt'
                sortOrderDefault = { false }
                type = { 'analytics' }
                urlApi = { '/analytics' }

            />
        </>
    );
}

export default AccountAnalytics;
