export const ru = {
    code:                  'ru',
    title:                 'Русский',
    l1:                    'Поздравляем с возвращением!',
    l2:                    'Введите электронную почту и пароль для входа в свой аккаунт.',
    l3:                    'Email',
    l4:                    'Пароль',
    l5:                    'Пользователь не найден',
    l6:                    'Некорректный пароль',
    l7:                    'Забыли пароль?',
    l8:                    'Логин',
    l9:                    'Неправильная ссылка приглашения',
    l10:                   'Добро пожаловать! Начните регистрацию!',
    l11:                   'Введите электронную почту и придумайте пароль, чтобы зарегистрироваться',
    l12:                   'Далее',
    cl1:                   'Ваше время',
    cl2:                   'Рабочее время',
    hb1:                   'Баланс телефонии',
    hb2:                   'Баланс услуг',
    hb3:                   'Входящий баланс телефонии',
    so:                    'Выйти',
    mn1:                   'Пользователи',
    mn2:                   'Компании',
    mn3:                   'Номера',
    mn4:                   'Статистика звонков',
    mn5:                   'Услуги',
    mn6:                   'Поставщики',
    mn7:                   'Аналитика',
    mn8:                   'Баланс услуг',
    mn9:                   'Настройки',
    mn10:                  'Профит',
    mn11:                  'Тех',
    mn12:                  'ATC',
    mn13:                  'Роли',
    mn14:                  'Аналитика Поставщиков',
    mn15:                  'Партнеры',
    sb1:                   'Скрыть панель',
    sb2:                   'Раскрыть панель',
    ad1:                   'Добавить пользователя',
    ad2:                   'Добавить компанию',
    ad3:                   'Добавить номер',
    ad4:                   'Добавить поставщика',
    ad5:                   'Добавить',
    ed1:                   'Редактировать пользователя',
    ed2:                   'Редактировать компанию',
    ed3:                   'Редактировать номер',
    ed4:                   'Редактировать поставщика',
    bt1:                   'Вернуться назад',
    bt2:                   'Сохранить',
    bt3:                   'Поиск',
    bt4:                   'Фильтры',
    bt5:                   'Сбросить фильтры',
    bt6:                   'Объединить',
    bt7:                   'Подключить номера компаний',
    bt8:                   'Загрузить номера поставщиков',
    bt9:                   'Отключить номера',
    bt10:                  'Скачать',
    bt11:                  'Пригласить пользователя',
    bt12:                  'Дополнительные услуги',
    bt13:                  'Сохранение',
    pg1:                   'Перейти к',
    tx1:                   'Пока здесь ничего, но это ненадолго! 😉',
    tx2:                   'Все',
    tx3:                   'в прошлом месяце',
    allMinute:             'всего минут',
    allSumm:               'всего затрачено $',
    tx5:                   'Страна',
    tx6:                   'страны',
    tx7:                   'Активный',
    tx8:                   'Неактивный',
    tx9:                   'Ошибка ответа сервера',
    t1:                    'Статистика звонков',
    t2:                    'Статистика регионов',
    t3:                    'Мои номера',
    t4:                    'Дополнительные услуги',
    t5:                    'Список',
    t6:                    'Аналитика',
    t7:                    'Модуль / Роль',
    st1:                   '% Дозвонов',
    st2:                   'Средняя продолжительность звонка',
    st3:                   'Звонки',
    st4:                   'Расходы',
    st5:                   'Информация о сеансах.',
    st6:                   'Время в звонке',
    st7:                   'Расходы по геолокации',
    st8:                   'Общая аналитика',
    st9:                   'Диаграмма',
    st10:                  'Динамика',
    st11:                  'Пополнение поставщика',
    st12:                  'Пополнение',
    st13:                  'Сумма пополнения',
    st14:                  'Пополнить',
    st15:                  'Введите сумму',
    st16:                  'Пополнение баланса',
    f1:                    'Имя пользователя',
    f2:                    'Телеграмм ID',
    f3:                    'Роль',
    f4:                    'Компания',
    f5:                    'Процент представителя',
    f6:                    'Процент партнера',
    f6_:                   'Процент партнера по трафику',
    f7:                    'Пароль',
    f8:                    'Email',
    f9:                    'Действие',
    f10:                   'Компания',
    f11:                   'Мор',
    f12:                   'АТС',
    f13:                   'Менеджер',
    f14:                   'Кредитный лимит',
    f15:                   'Список Мор',
    f16:                   'Дата платежа',
    f17:                   'Баланс услуг/баланс телефонии',
    f18:                   'Партнер',
    f19:                   'Страна',
    f20:                   'Телефон',
    f21:                   'Тип',
    f22:                   'Поставщик',
    f23:                   'Цена п-ка',
    f24:                   'Абонплата п-ка',
    f25:                   'Цена для компании',
    f25_:                  'Сумма за дзвонок',
    f26:                   'Абонплата для компании',
    f27:                   'Удаление поставщика',
    f28:                   'Резерв',
    f29:                   'Дата создания',
    f30:                   'Дата',
    f31:                   'Номер',
    f32:                   'Звонок в',
    f33:                   'Длительность',
    f34:                   'Статус',
    f35:                   'Причина зависания',
    f36:                   'Сервер',
    profit:                'Прибуток',
    f37:                   'Прибыль',
    f38:                   'Дата подключения',
    f39:                   'Дата следующего списания',
    f40:                   'Стоимость подключения',
    f41:                   'Абонплата за номер',
    f42:                   'Название',
    f43:                   'Абонплата',
    f44:                   'Баланс',
    f45:                   'Списание абон. при подключении',
    f45_:                  'Списание за подключение',
    f46:                   'Представитель',
    f47:                   'Процент представителя',
    f49:                   'Комментарий',
    f50:                   'Сумма',
    f50_:                  'Сума АТС',
    f51:                   'Услуга',
    salePrice:             'Сумма транзакции',
    buyPrice:              'Сумма закупки',
    survisorCompanyProfit: 'Премия менеджера',
    survisorPostmanProfit: 'Премия сюр',
    parnerCompanyProfit:   'Премия партнера',
    f57:                   'Списано/пополнено',
    f58:                   'Остаток',
    f59:                   'Описание',
    f60:                   'На Удаление',
    f61:                   'Проверка на спам',
    f62:                   'СМС',
    f63:                   'Общий прибыль',
    f65:                   'Прибыль по звонкам',
    f66:                   'Прибыль по номерам',
    f67:                   'Прибыль по АТС',
    f68:                   'Прибыль по смс',
    f69:                   'Прибыль по проверке на спам',
    f70:                   'Інформация',
    f71:                   'Количество линий',
    f72:                   'Цена за мин поставщика',
    f73:                   'Цена за мин',
    f74:                   'Дата подключения партнера',
    f75:                   'Atc сумма закупки',
    f76:                   'Atc цена комании',
    f77:                   'Менеджери',
    in1:                   'Выберите тип',
    in2:                   'Это поможет нам определить, какие документы нужно подготовить.',
    in3:                   'Физическое лицо',
    in4:                   'Юридическое лицо',
    in5:                   'Продолжить',
    in6:                   'Введите персональные данные',
    in7:                   'Заполните дополнительную информацию, чтобы мы могли убедиться в вашей идентичности',
    in8:                   'Я ознакомился (-лась) и принимаю',
    in9:                   'Условия и Правила',
    in10:                  'платформы.',
    in11:                  'Загрузите документы',
    in12:                  'Для завершения процесса верификации загрузите фото вашего паспорта c двух сторон.',
    in13:                  'Убедитесь, что:',
    in14:                  'Фото четкое и без размытия.',
    in15:                  'Все углы документа видны полностью.',
    in16:                  'Текст и изображение легко прочитать.',
    in17:                  'JPEG, PNG, PDF (до 5 МБ)',
    in18:                  'Мы работаем над вашей верификацией!',
    in19:                  'Как только мы завершим вашу верификацию, вы получите доступ к вашему аккаунту',
    in20:                  'Обычно это занимает до 2 часов.',
    in21:                  'Если у вас возникли вопросы, обратитесь к нашему менеджеру за помощью.',
    in22:                  'Введите имя компании',
    in23:                  'Скачайте договор',
    in24:                  'Ознакомьтесь с договором, загрузив его по ссылке.',
    in25:                  'Загрузите договор',
    in26:                  'Для завершения процесса верификации загрузите фото вашего договора.',
    in27:                  'Имя',
    in28:                  'Фамилия',
    in29:                  'Имя компании',
    in30:                  'Соглашение',
    in31:                  'Введите номер или количество линий АТС',
    in32:                  'Введите номер',
    com1:                  'Пополнение на USDT',
    com2:                  'Списание с баланса телефонии',
    com3:                  'Возврат средств за услуги',
    com4:                  'Перерасчет стоимости за услуги',
    com5:                  'Пополнение на WISE',
    com6:                  'кредит нота',
    com7:                  'Пополнение по инвойсу №',
    cr1:                   'Списание абонплаты за АТС',
    cr2:                   'линий',
    cr3:                   'списание абонплаты за номер',
    cr4:                   'списание абонплаты за',
    cr5:                   'ручное пополнение',
    cr6:                   'ручное списание',
    r1:                    'Модуль / Роль',
    r2:                    'Все поля',
    r3:                    'Показать модуль',
    r4:                    'Писать',
    r5:                    'Добавить',
    r6:                    'Новая роль',
    r7:                    'Показать колонку Admin',
    m1:                    'Подтвердите удаление',
    m2:                    'Вы уверены, что хотите удалить элемент?',
    m3:                    'Да',
    m4:                    'Нет',
    r_admin:               'Админ',
    r_client:              'Клиент',
    r_manager:             'Менеджер',
    r_partner:             'Партнер',
    currentLines:          'Количество линий в системе',
    totalLines:            'Количество линий с АТС',
    newLine:               'Подключенные линии',
    removeLine:            'Отключены линии',
    summ:                  'Сума',
    summ_purchase:         'Сума',
    line_atc:              'линии атс',
    spamcheck:             'спамчекер',
    validate_numb:         'валидность номеров',
    action_confirmation:   'подтверждение действия',
    action_confirm:        'Вы уверены, что хотите выполнить это действие?',
    my_profile:            'Мой профиль',
    personal_info:         'Персональная информация',
    first_name:            'Имя',
    last_name:             'Фамилия',
    save:                  'Сохранить',
    time_settings:         'Настройки времени',
    password:              'Пароль',
    new_password:          'Новый пароль',
    change_password:       'Сменить пароль',
    logout:                'Выход',
    logout_platform:       'Выйти из платформы',
    confirm_action:        'Подтвердите действие',
    save_changes_confirm:  'Вы действительно хотите сохранить изменения?',
    no:                    'Нет',
    yes:                   'Да',
    success:               'Успешно',
    changes_saved:         'Изменения успешно сохранены.',

};
