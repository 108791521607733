import React, { useState, useEffect } from 'react';
import StylesStat from '../../../theme/styles/stat.module.scss';
import PieChart from '../Chart/PieChart';
import { api } from '../../../Api';
import { useSelector } from 'react-redux';

const selectState = (state) => ({
    translate: state.swapi.translate,
    company:   state.swapi.company,

});

function Charts(props) {
    const { filter, tab } = props;
    const state = useSelector(selectState);
    const translate = state.translate;
    const companyId = state?.company?.id;

    const [ statCalls, setStatCalls ] = useState([]);
    const [ statRegion, setStatRegion ] = useState([]);

    useEffect(() => {
        const getData = async () => {
            const data =  await api.postData({ filter: { companyId: companyId, ...filter } }, `/getStatsCalls`);
            if (data.dataStasAll) {
                setStatCalls(data?.dataStasAll || []);
                setStatRegion(data?.dataStatRegion || []);
            }
        };
        getData();
    }, [ filter, tab ]);

    return (
        <>
            {tab === 0 && (
                <div className = { `${StylesStat.statCalls}` }>
                    { statCalls.map((item, index) => {
                        // Перевірка, чи це "acd" і перетворення в хв:сек
                        const formattedValue = item.name === 'st2' ? (() => {
                            const acdInSeconds = item.value;  // Вихідне значення в секундах
                            const minutes = Math.floor(acdInSeconds / 60);  // Вираховуємо кількість хвилин
                            const seconds = (acdInSeconds % 60).toFixed(0);  // Округлюємо кількість секунд до двох знаків після коми

                            return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;  // Форматуємо як хв:сек
                        })() : item.value;


                        return (
                            <div
                                className = { StylesStat.statCallsItem }
                                key = { index }>
                                <div className = { `${StylesStat.top}` }>
                                    <div className = { `${StylesStat.name}` }>
                                        {translate[ item.name ] || item.name}
                                    </div>
                                    <div className = { `${StylesStat.info}` }>
                                        <img
                                            alt = ''
                                            src = '/icons/info.svg'
                                        />
                                        <div className = { `${StylesStat.tooltip}` }>
                                            {translate[ item.info ] || item.info}
                                        </div>
                                    </div>
                                </div>
                                <div className = { `${StylesStat.data}` }>
                                    <div className = { `${StylesStat.value}` }>
                                        {formattedValue}  {/* Виведення значення з форматуванням */}
                                    </div>
                                    <div className = { `${StylesStat.dynamic} ${item.dynamic > 0 ? StylesStat.up : StylesStat.down}` }>
                                        <img
                                            alt = ''
                                            src = { `/icons/d_${item.dynamic > 0 ? 'up' : 'down'}.svg` }
                                        />
                                        {`${item.dynamic > 0 ? '+' : ''}${item.dynamic}%`}
                                        <span className = { `${StylesStat.lm}` }>{translate.tx3}</span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}

                </div>
            ) }
            {tab === 1 && (
                <div className = { `${StylesStat.statRegion}` }>
                    { statRegion.map((item, index)=>(
                        <div
                            className = { StylesStat.statRegionItem }
                            key = { index }>
                            <div className = { `${StylesStat.top}` }>
                                <div className = { `${StylesStat.name}` }>
                                    { translate[ item.name ] || item.name }
                                </div>
                                <div className = { `${StylesStat.info}` }>
                                    { `${item.info} ${ translate.tx6 }` }
                                </div>
                            </div>
                            <div className = { `${StylesStat.data}` }>
                                <div className = { `${StylesStat.chart}` }>
                                    <PieChart
                                        data = { item.countries }
                                    />
                                    <div className = { `${StylesStat.total}` }>
                                        { translate[ item.txt ] }
                                        <div className = { `${StylesStat.value}` }>
                                            { item.total }
                                        </div>
                                    </div>
                                </div>
                                <div className = { `${StylesStat.list}` }>
                                    <div
                                        className = { StylesStat.listItem }
                                        key = { index }>
                                        <div
                                            className = { `${StylesStat.dot}` }
                                        />
                                        <div className = { `${StylesStat.name} ${StylesStat.header}` }>
                                            { translate.tx5 }
                                        </div>
                                        <div className = { `${StylesStat.value} ${StylesStat.header}` }>
                                        </div>
                                    </div>
                                    { item.countries.map((country, i)=>(
                                        <div
                                            className = { StylesStat.listItem }
                                            key = { i }>
                                            <div
                                                className = { `${StylesStat.dot}` }
                                                style = {{ backgroundColor: country.color }}
                                            />
                                            <div className = { `${StylesStat.name}` }>
                                                { country.name }
                                            </div>
                                            <div className = { `${StylesStat.value}` }>
                                                { `${country?.summ.toFixed(2)} ${item?.is_percentage ? '%' : '' }` }
                                            </div>
                                        </div>
                                    )) }
                                </div>
                            </div>
                        </div>
                    )) }
                </div>
            ) }
        </>
    );
}

export default Charts;
