import React, { useEffect, useState } from 'react';
import Styles from '../../../theme/styles/pageHeader.module.scss';
import StylesTable from '../../../theme/styles/table.module.scss';
import StylesForm from '../../../theme/styles/form.module.scss';
import { useSelector } from 'react-redux';
import { listing } from './listing';
import { api } from '../../../Api';
import { AddRole } from './add';
import { confirmAlert } from 'react-confirm-alert';
import AccountRoleField from './field';

const selectState = (state) => ({
    userData:  state.swapi.userData,
    translate: state.swapi.translate,
});

function AccountRoles() {
    const [ rolesList, setRolesList ] = useState({});
    const [ roles, setRoles ] = useState([ 'admin' ]);
    const [ isSaved, setIsSaved ] = useState(false);
    const [ showAdm, setShowAdm ] = useState(false);
    const state = useSelector(selectState);
    const translate = state.translate;

    const getData = async () => {
        const data = await api.postData({ type: 'roles' }, '/getRoles');
        if (data?.admin) {
            setRolesList(data);
            setRoles(Object.keys(data));
        } else {
            const _data = {};
            for (const role of roles) {
                if (!_data[ role ]) {
                    _data[ role ] = {};
                }
                for (const item of listing) {
                    if (!_data[ role ][ item.module.key ]) {
                        _data[ role ][ item.module.key ] = {
                            module: {
                                read:  role === 'admin',
                                write: role === 'admin',
                            },
                            fields: {},
                        };
                    }
                    _data[ role ][ item.module.key ].fields.all = role === 'admin';
                    for (const field of item.fields) {
                        if (!_data[ role ][ item.module.key ].fields[ field.key ]) {
                            _data[ role ][ item.module.key ].fields[ field.key ] = role === 'admin';
                        }
                    }
                }
            }
            setRolesList(_data);
        }
    };

    const getNewModule = (moduleName) => {
        let result = null;
        const _module = listing.find((el)=>el.module?.key === moduleName);
        if (_module) {
            if (_module) {
                result = {
                    module: _module.module,
                    fields: _module.fields.reduce((acc, { key }) => {
                        acc[ key ] = false;

                        return acc;
                    }, {}),
                };
            }
        }

        return result;
    };

    const setAccess = (props) => {
        const { role, module, field } = props;
        const _rolesList = { ...rolesList };
        if (role && module && field && !_rolesList[ role ][ module ]) {
            const _module = listing.find((el)=>el.module?.key === module);
            if (_module) {
                if (_module) {
                    _rolesList[ role ][ module ] = getNewModule(module);
                }
            }
        }
        if (role && module && field && _rolesList[ role ][ module ]?.fields) {
            _rolesList[ role ][ module ].fields[ field ]
            = !_rolesList[ role ][ module ].fields[ field ];
        }
        setRolesList(_rolesList);
    };

    const setModuleAccess = (props) => {
        const { role, module, field } = props;
        const _rolesList = { ...rolesList };
        if (role && module && field && !_rolesList[ role ][ module ]) {
            const _module = listing.find((el)=>el.module?.key === module);
            if (_module) {
                if (_module) {
                    _rolesList[ role ][ module ] = getNewModule(module);
                }
            }
        }
        if (role && module && field && _rolesList[ role ][ module ]?.module) {
            _rolesList[ role ][ module ].module[ field ]
            = !_rolesList[ role ][ module ].module[ field ];
        }
        setRolesList(_rolesList);
    };

    const setAllAccess = (props) => {
        const { role, module } = props;
        const _rolesList = { ...rolesList };
        if (role && module && _rolesList[ role ] && !_rolesList[ role ][ module ]) {
            const _module = [ ...listing ].find((el)=>el.module?.key === module);
            if (_module) {
                _rolesList[ role ][ module ] = getNewModule(module);
            }
        }
        if (role && module && _rolesList[ role ] && _rolesList[ role ][ module ]) {
            _rolesList[ role ][ module ].fields.all = !_rolesList[ role ][ module ].fields.all;
            for (const key of Object.keys(_rolesList[ role ][ module ].fields)) {
                _rolesList[ role ][ module ].fields[ key ]
                = _rolesList[ role ][ module ].fields.all;
            }
        }
        setRolesList(_rolesList);
    };

    const saveRoles = async () => {
        setIsSaved(true);
        await api.postData({ roles: rolesList }, '/setRoles');
    };

    const deleteRole = (role) => {
        confirmAlert({
            title:            translate.m1,
            message:          translate.m2,
            overlayClassName: 'confirmAlertOverlay',
            buttons:          [
                {
                    label:   translate.m3,
                    onClick: async () => {
                        await api.postData({ role: role }, '/deleteRoles');
                        const _rolesList = { ...rolesList };
                        delete _rolesList[ role ];
                        setRolesList(_rolesList);
                        setRoles((prev)=>prev.filter((el)=>el !== role));
                    },
                    className: 'confirm',
                },
                {
                    label:     translate.m4,
                    className: 'cancel',
                },
            ],
        });
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (isSaved) {
            const timer = setTimeout(() => {
                setIsSaved(false);
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [ isSaved ]);

    useEffect(() => {
        if (roles.length > 1) {
            const _rolesList = { ...rolesList };
            for (const role of roles) {
                if (!_rolesList[ role ]) {
                    _rolesList[ role ] = {};
                    for (const item of listing) {
                        if (!_rolesList[ role ][ item.module.key ]) {
                            _rolesList[ role ][ item.module.key ] = {
                                module: {
                                    read:  role === 'admin',
                                    write: role === 'admin',
                                },
                                fields: {},
                            };
                        }
                        _rolesList[ role ][ item.module.key ].fields.all = role === 'admin';
                        if (item.fields) {
                            for (const field of item.fields) {
                                if (!_rolesList[ role ][ item.module.key ].fields[ field.key ]) {
                                    _rolesList[ role ][ item.module.key ].fields[ field.key ] = role === 'admin';
                                }
                            }
                        }
                    }
                }
            }
            setRolesList(_rolesList);
        }
    }, [ roles ]);

    const headerJSX = roles.map((el)=>{
        return (
            <React.Fragment key = { el }>
                { (showAdm || el !== 'admin') && (
                    <td>
                        <div className = { `${StylesTable.rolesHeader}` }>
                            <div className = { `${StylesTable.name}` }>
                                { el }
                            </div>
                            { el !== 'admin' && (
                                <div
                                    className = { `${StylesForm.btnOutline}` }
                                    onClick = { () => deleteRole(el) }>
                                    <svg
                                        fill = 'none'
                                        height = '14'
                                        viewBox = '0 0 12 14'
                                        width = '12'
                                        xmlns = 'http://www.w3.org/2000/svg'>
                                        <path
                                            clipRule = 'evenodd'
                                            d = 'M8.83983 2.33331L8.66667 2.33331C8.66667 1.22874 7.77124 0.333313 6.66667 0.333313H5.33333C4.22876 0.333313 3.33333 1.22874 3.33333 2.33331L2.89552 2.33331L2.66667 2.33331L2.66588 2.33331L0.666667 2.33331C0.298477 2.33331 0 2.63179 0 2.99998C0 3.36817 0.298477 3.66665 0.666667 3.66665L1.33333 3.66665L1.33333 11.6666C1.33333 12.7712 2.22877 13.6666 3.33333 13.6666H8.66667C9.77124 13.6666 10.6667 12.7712 10.6667 11.6666L10.6667 3.66665L11.3333 3.66665C11.7015 3.66665 12 3.36817 12 2.99998C12 2.63179 11.7015 2.33331 11.3333 2.33331L9.33456 2.33331L9.33333 2.33331L8.83983 2.33331ZM9.33333 3.66665H8L7.33333 3.66665L4.66667 3.66665L4 3.66665L2.66667 3.66665L2.66667 4.99998L2.66667 11.6666C2.66667 12.0348 2.96514 12.3333 3.33333 12.3333H8.66667C9.03486 12.3333 9.33333 12.0348 9.33333 11.6666L9.33333 4.99998V3.66665ZM4.66667 2.33331H5.33333H6H6.66667H7.33333C7.33333 1.96512 7.03486 1.66665 6.66667 1.66665L5.33333 1.66665C4.96514 1.66665 4.66667 1.96512 4.66667 2.33331ZM4.66667 4.99998C5.03486 4.99998 5.33333 5.29846 5.33333 5.66665V10.3333C5.33333 10.7015 5.03486 11 4.66667 11C4.29848 11 4 10.7015 4 10.3333V5.66665C4 5.29846 4.29848 4.99998 4.66667 4.99998ZM8 5.66665C8 5.29846 7.70152 4.99998 7.33333 4.99998C6.96514 4.99998 6.66667 5.29846 6.66667 5.66665V10.3333C6.66667 10.7015 6.96514 11 7.33333 11C7.70152 11 8 10.7015 8 10.3333V5.66665Z'
                                            fill = '#EE3434'
                                            fillRule = 'evenodd'
                                        />
                                    </svg>
                                </div>
                            )}
                        </div>
                    </td>
                ) }
            </React.Fragment>
        );
    });

    const listJSX = listing.map((el)=>{
        const rolesJSX = roles.map((r)=>{
            const fieldsJSX = el?.fields?.map((f)=>{
                return (
                    <AccountRoleField
                        checked = { rolesList[ r ]?.[ el.module.key ]?.fields?.[ f.key ] }
                        field = { f.key }
                        fieldName = { translate[ f.name ] }
                        key = { f.key }
                        module = { el.module.key }
                        role = { r }
                        setAccess = { setAccess }
                    />
                );
            });

            return (
                <React.Fragment key = { r  }>
                    { (showAdm || r !== 'admin') && (
                        <td>
                            <div className = { `${StylesTable.roles}` }>
                                <div className = { `${StylesTable.moduleWrap}` }>
                                    <AccountRoleField
                                        checked = {
                                            rolesList[ r ]?.[ el.module.key ]?.module?.read
                                        }
                                        field = { 'read' }
                                        fieldName = { translate.r3 }
                                        module = { el.module.key }
                                        role = { r }
                                        setAccess = { setModuleAccess }
                                    />
                                </div>
                                { el?.fields?.length > 0 && (
                                    <div className = { `${StylesTable.roleWrap}` }>
                                        <AccountRoleField
                                            checked = {
                                                rolesList[ r ]?.[ el.module.key ]?.fields?.all
                                            }
                                            field = { 'all' }
                                            fieldName = { translate.r2 }
                                            module = { el.module.key }
                                            role = { r }
                                            setAccess = { setAllAccess }
                                        />
                                        { fieldsJSX }
                                    </div>
                                )}
                            </div>
                        </td>
                    ) }
                </React.Fragment>
            );
        });

        return (
            <tr key = { el.module.key }>
                <td>
                    { translate[ el.module.name ] }
                </td>
                { rolesJSX }
            </tr>
        );
    });

    return (
        <>
            <div className = { Styles.header }>
                <div className = { Styles.title }>
                    { translate.mn13 }
                </div>
                <div className = { `${Styles.filter}` }>
                    <div className = { `${Styles.filter}` }>
                        <div className = { `${Styles.filter} ${Styles.noWrap}` }>
                            { translate.r7 }
                        </div>
                        <div className = { `${StylesForm.checkboxField} ${Styles.checkboxField}` }>
                            <div
                                className = { `${StylesForm.checkbox}
                                ${showAdm ? StylesForm.checked : ''}
                                ` }
                                onClick = { ()=>setShowAdm(!showAdm) }>
                                <svg
                                    fill = 'none'
                                    height = '9'
                                    viewBox = '0 0 12 9'
                                    width = '12'
                                    xmlns = 'http://www.w3.org/2000/svg'>
                                    <path
                                        d = 'M11.3334 1L4.00002 8.33333L0.666687 5'
                                        stroke = 'white'
                                        strokeLinecap = 'round'
                                        strokeLinejoin = 'round'
                                        strokeWidth = '1.25'
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <AddRole
                        setRoles = { setRoles }
                    />
                    <div className = { `${StylesForm.btns}` }>
                        <div
                            className = { `${StylesForm.button} ${isSaved ? StylesForm.warning : ''}` }
                            onClick = { saveRoles }>
                            { isSaved ? translate.bt13 : translate.bt2 }
                        </div>
                    </div>
                </div>
            </div>
            <div className = { `${StylesTable.table}` }>
                <table>
                    <thead>
                        <tr>
                            <td>{ translate.r1 }</td>
                            {headerJSX}
                        </tr>
                    </thead>
                    <tbody>
                        {listJSX}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default AccountRoles;
