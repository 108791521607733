import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ListConstructor from '../ListConstructor';
import { listing } from './listing';
import Tabs from '../ListConstructor/tabs';
import Charts from './charts';
import Diagram from './diagram';
import  { Txt }  from './txt';
import { setTraslate } from '../../../instruments/setTraslate';

function AccountProfit(props) {
    const selectState = (state) => ({
        translate: state.swapi.translate,
    });
    const state = useSelector(selectState);
    const translate = state.translate;
    const type = props.type;
    const now = new Date();
    const start = new Date(now.setUTCDate(1)).toISOString();
    const dateFilter = { period: { from: start, to: new Date().toISOString() } };
    const [ filter, setFilter ] = useState(dateFilter);
    const [ list, setList ] = useState({ ...listing.list });
    useEffect(()=>{
        const _list = setTraslate({ ...listing.list }, translate);
        setList(_list);
    }, [ translate, listing.list ]);

    const CustomComponents = {
        totalCalls:  Txt,
        totalNumber: Txt,
        totalAtc:    Txt,
        totalSpam:   Txt,
        totalSms:    Txt,
        totalAll:    Txt,

    };
    const tabs = [
        { name: translate.st8, to: '/admin/profit/1', active: type === '1' },
        { name: translate.st9, to: '/admin/profit/2', active: type === '2' },
        { name: translate.st10, to: '/admin/profit/3', active: type === '3' },
    ];

    const setCallBack = (value) => {
        setFilter(value);
    };

    return (
        <>
            <Tabs tabs = { tabs }/>
            {type === '1' ? (
                <>
                    <Charts
                        filter = { filter }
                    />
                    <ListConstructor
                        { ...props }
                        isShowAddBtn
                        CustomComponents = { CustomComponents }
                        fields = { list }
                        filterOutside = { filter }
                        limit = { 30 }
                        moduleName = { 'profit/1' }
                        setCallBack = { setCallBack }
                        sortDefault = 'createdAt'
                        sortOrderDefault = { false }
                        type = { 'profit' }
                        urlApi = { '/profit' }


                    />
                </>
            ) : null}

            {type === '2' ? (
                <>
                    <Diagram
                        filter = { filter }
                    />
                </>
            ) : null}

            {type === '3' ? (
                <>
                    <ListConstructor
                        { ...props }
                        isShowAddBtn
                        CustomComponents = { CustomComponents }
                        fields = { list }
                        filterOutside = { filter }
                        limit = { 30 }
                        moduleName = { 'profit/3' }
                        setCallBack = { setCallBack }
                        sortDefault = 'createdAt'
                        sortOrderDefault = { false }
                        type = { 'profitDynamic' }
                        urlApi = { '/profitDynamic' }

                    />
                </>
            ) : null}


        </>

    );
}

export default AccountProfit;
