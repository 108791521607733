
const listing = {};

listing.list = {
    managerId: {
        type:   'text',
        title:  'f13',
        width:  '10',
        filter: 'SearchV2',

    },
    companyId: {
        type:   'text',
        title:  'f10',
        width:  '10',
        filter: 'SearchV2',
    },
    comment: {
        type:  'text',
        title: 'f49',
        width: '20',
    },
    phoneNumb: {
        type:   'text',
        title:  'f20',
        width:  '10',
        filter: 'Input',
    },
    serviceId: {
        type:   'text',
        title:  'f51',
        width:  '10',
        filter: 'Select',


    },
    salePrice: {
        type:  'text',
        title: 'salePrice',
        width: '5',
        sort:  true,

    },
    buyPrice: {
        type:  'text',
        title: 'buyPrice',
        width: '5',
        sort:  true,

    },
    survisorCompanyProfit: {
        type:  'text',
        title: 'survisorCompanyProfit',
        width: '5',
        sort:  true,
    },
    survisorPostmanProfit: {
        type:  'text',
        title: 'survisorPostmanProfit',
        width: '5',
        sort:  true,
    },
    parnerCompanyProfit: {
        type:  'text',
        title: 'parnerCompanyProfit',
        width: '5',
        sort:  true,
    },
    profit: {
        type:  'text',
        title: 'profit',
        width: '5',
        sort:  true,

    },
    createdAt: {
        type:   'date',
        title:  'f30',
        width:  '10',
        filter: 'DateRangeField',
        sort:   true,
    },
    delete: {
        type:   'delete',
        title:  'f9',
        values: [ 'delete'  ],
        width:  '1',
    },
};

export { listing };
