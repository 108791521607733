import React, { useState } from 'react';
import Styles from './styles.module.scss';
import { useSelector } from 'react-redux';

const selectState = (state) => ({
    translate: state.swapi.translate,
});

function StepDoc(props) {
    const { role } = props;
    const state = useSelector(selectState);
    const translate = state.translate;
    const [ files, setFiles ] = useState([]);
    const [ error, setError ] = useState({});
    const [ isDragging, setIsDragging ] = useState(false);

    const onClick = () => {
        const error = {};
        if (files.length < 2) {
            error.files = true;
        }
        if (Object.keys(error).length === 0 && props.callback) {
            props.callback({ files });
        } else {
            setError(error);
        }
    };

    const deleteFile = (file) => {
        setFiles((prev)=>{
            return prev.filter((item)=>item.name !== file.name);
        });
    };

    const handleFiles = (fileList) => {
        setError(""); // Скидання попередньої помилки
        const existingFiles = new Set(files.map((file) => file.name + file.size));

        const droppedFiles = Array.from(fileList).filter((file) => {
            const uniqueKey = file.name + file.size;

            if (existingFiles.has(uniqueKey)) {
                return false;
            }
            if (![ "image/jpeg", "image/png", "application/pdf" ].includes(file.type)) {
                return false;
            }
            if (file.size > 7 * 1024 * 1024) {
                return false;
            }

            return true;
        });

        setFiles((prevFiles) => [ ...prevFiles, ...droppedFiles ]);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false); // Вимикаємо підсвічування
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setIsDragging(false);
        handleFiles(event.dataTransfer.files);
    };

    const handleFileInputChange = (event) => {
        handleFiles(event.target.files);
    };

    return (
        <>
            <div className = { Styles.titles }>
                <div className = { Styles.title }>
                    { role === 1 ? translate.in11 : translate.in25 }
                </div>
                <div className = { Styles.subtitle }>
                    { role === 1 ? translate.in12 : translate.in26 }
                </div>
            </div>
            <div className = { Styles.upload }>
                { role === 1 && (
                    <div className = { Styles.example }>
                        <div className = { Styles.images }>
                            <div className = { Styles.image }>
                                <img
                                    alt = ''
                                    src = { '/images/ver2.jpg' }
                                />
                                <svg
                                    fill = 'none'
                                    height = '25'
                                    viewBox = '0 0 25 25'
                                    width = '25'
                                    xmlns = 'http://www.w3.org/2000/svg'>
                                    <rect
                                        fill = '#019939'
                                        height = '24.5036'
                                        rx = '11.8227'
                                        width = '23.6453'
                                        x = '0.414062'
                                        y = '0.203453'
                                    />
                                    <g clipPath = 'url(#clip0_1155_35964)'>
                                        <path
                                            d = 'M10.6105 14.8137L8.71399 12.8483C8.50266 12.6293 8.1667 12.6293 7.95537 12.8483C7.74404 13.0673 7.74404 13.4154 7.95537 13.6344L10.2258 15.9873C10.4371 16.2063 10.7785 16.2063 10.9899 15.9873L16.7337 10.0406C16.945 9.82157 16.945 9.47341 16.7337 9.25441C16.5224 9.03541 16.1864 9.03541 15.9751 9.25441L10.6105 14.8137Z'
                                            fill = 'white'
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id = 'clip0_1155_35964'>
                                            <rect
                                                fill = 'white'
                                                height = '13.477'
                                                transform = 'translate(5.73438 5.71664)'
                                                width = '13.0049'
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div className = { Styles.image }>
                                <img
                                    alt = ''
                                    className = { Styles.blur }
                                    src = { '/images/ver2.jpg' }
                                />
                                <svg
                                    fill = 'none'
                                    height = '25'
                                    viewBox = '0 0 24 25'
                                    width = '24'
                                    xmlns = 'http://www.w3.org/2000/svg'>
                                    <rect
                                        fill = '#EE3434'
                                        height = '24.5036'
                                        rx = '11.8227'
                                        width = '23.6453'
                                        x = '0.29541'
                                        y = '0.203453'
                                    />
                                    <g clipPath = 'url(#clip0_1155_35968)'>
                                        <path
                                            d = 'M15.5325 8.92316C15.3211 8.70416 14.9798 8.70416 14.7684 8.92316L12.1187 11.6635L9.46892 8.91755C9.25759 8.69855 8.91621 8.69855 8.70488 8.91755C8.49355 9.13655 8.49355 9.49032 8.70488 9.70932L11.3546 12.4553L8.70488 15.2012C8.49355 15.4202 8.49355 15.774 8.70488 15.993C8.91621 16.212 9.25759 16.212 9.46892 15.993L12.1187 13.247L14.7684 15.993C14.9798 16.212 15.3211 16.212 15.5325 15.993C15.7438 15.774 15.7438 15.4202 15.5325 15.2012L12.8827 12.4553L15.5325 9.70932C15.7384 9.49594 15.7384 9.13655 15.5325 8.92316Z'
                                            fill = 'white'
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id = 'clip0_1155_35968'>
                                            <rect
                                                fill = 'white'
                                                height = '13.477'
                                                transform = 'translate(5.61621 5.71664)'
                                                width = '13.0049'
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>

                            </div>
                        </div>
                        <div className = { Styles.list }>
                            <div className = { Styles.title }>
                                { translate.in13 }
                            </div>
                            <div className = { Styles.item }>
                                { translate.in14 }
                            </div>
                            <div className = { Styles.item }>
                                { translate.in15 }
                            </div>
                            <div className = { Styles.item }>
                                { translate.in16 }
                            </div>
                        </div>
                    </div>
                ) }
                <label
                    className = { `${Styles.files} ${error.files ? Styles.error : ''} ${isDragging ? Styles.hover : ''}` }
                    htmlFor = 'fileInput'
                    onDragEnter = { handleDragOver }
                    onDragLeave = { handleDragLeave }
                    onDragOver = { handleDragOver }
                    onDrop = { handleDrop }>
                    <input
                        multiple
                        accept = '.jpg,.jpeg,.png,.pdf'
                        id = 'fileInput'
                        style = {{ display: "none" }}
                        type = 'file'
                        onChange = { handleFileInputChange }
                    />
                    <svg
                        fill = 'none'
                        height = '20'
                        viewBox = '0 0 20 20'
                        width = '20'
                        xmlns = 'http://www.w3.org/2000/svg'>
                        <path
                            d = 'M5.5 13C3.567 13 2 11.433 2 9.5C2 7.69163 3.37146 6.20358 5.13102 6.01922C5.04553 5.69382 5 5.35223 5 5C5 2.79086 6.79086 1 9 1C10.8788 1 12.4551 2.29538 12.8845 4.04175C13.0857 4.01422 13.2912 4 13.5 4C15.9853 4 18 6.01472 18 8.5C18 10.9853 15.9853 13 13.5 13H11V9.41421L12.2929 10.7071C12.6834 11.0976 13.3166 11.0976 13.7071 10.7071C14.0976 10.3166 14.0976 9.68342 13.7071 9.29289L10.7071 6.29289C10.3166 5.90237 9.68342 5.90237 9.29289 6.29289L6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071C6.68342 11.0976 7.31658 11.0976 7.70711 10.7071L9 9.41421L9 13H5.5Z'
                            fill = '#ADADAD'
                        />
                        <path
                            d = 'M9 13H11L11 18C11 18.5523 10.5523 19 10 19C9.44772 19 9 18.5523 9 18L9 13Z'
                            fill = '#ADADAD'
                        />
                    </svg>
                    <div className = { Styles.title }>
                        { 'Upload or drop here' }
                    </div>
                    <div className = { Styles.item }>
                        { translate.in17 }
                    </div>
                </label>
                <div className = { Styles.fileslist }>
                    {files.map((file, index) => (
                        <div
                            className = { Styles.item }
                            key = { index }>
                            <div className = { Styles.left }>
                                <div className = { Styles.name }>
                                    {file.name}
                                </div>
                                <div className = { Styles.size }>
                                    {`${Math.ceil(file.size / 1024)} KB`}
                                </div>
                            </div>
                            <div className = { Styles.right }>
                                <svg
                                    fill = 'none'
                                    height = '24'
                                    viewBox = '0 0 24 24'
                                    width = '24'
                                    xmlns = 'http://www.w3.org/2000/svg'>
                                    <path
                                        clipRule = 'evenodd'
                                        d = 'M19.6987 6.28456C20.0938 6.67043 20.1013 7.30355 19.7155 7.69867L9.94984 17.6987C9.75306 17.9002 9.48044 18.0093 9.19897 17.9994C8.91751 17.9894 8.6533 17.8612 8.47128 17.6463L4.23691 12.6463C3.87998 12.2248 3.9323 11.5938 4.35376 11.2369C4.77521 10.88 5.40621 10.9323 5.76314 11.3537L9.28704 15.5148L18.2846 6.30133C18.6704 5.9062 19.3036 5.89869 19.6987 6.28456Z'
                                        fill = '#019939'
                                        fillRule = 'evenodd'
                                    />
                                </svg>
                                <svg
                                    className = { Styles.del }
                                    fill = 'none'
                                    height = '24'
                                    viewBox = '0 0 24 24'
                                    width = '24'
                                    xmlns = 'http://www.w3.org/2000/svg'
                                    onClick = { ()=>deleteFile(file) }>
                                    <path
                                        clipRule = 'evenodd'
                                        d = 'M16.2597 5L16 5C16 3.34315 14.6569 2 13 2H11C9.34314 2 8 3.34315 8 5L7.34328 5L7 5L6.99882 5L4 5C3.44772 5 3 5.44772 3 6C3 6.55229 3.44772 7 4 7L5 7L5 19C5 20.6569 6.34315 22 8 22H16C17.6569 22 19 20.6569 19 19L19 7L20 7C20.5523 7 21 6.55228 21 6C21 5.44771 20.5523 5 20 5L17.0018 5L17 5L16.2597 5ZM17 7H15L14 7L10 7L9 7L7 7L7 9L7 19C7 19.5523 7.44772 20 8 20H16C16.5523 20 17 19.5523 17 19L17 9V7ZM10 5H11H12L13 5H14C14 4.44772 13.5523 4 13 4L11 4C10.4477 4 10 4.44772 10 5ZM10 9C10.5523 9 11 9.44772 11 10V17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17V10C9 9.44772 9.44772 9 10 9ZM15 10C15 9.44772 14.5523 9 14 9C13.4477 9 13 9.44772 13 10V17C13 17.5523 13.4477 18 14 18C14.5523 18 15 17.5523 15 17V10Z'
                                        fill = '#272727'
                                        fillRule = 'evenodd'
                                    />
                                </svg>

                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div
                className = { Styles.btn }
                onClick = { onClick }>
                { translate.in5 }
                <svg
                    fill = 'none'
                    height = '20'
                    viewBox = '0 0 20 20'
                    width = '20'
                    xmlns = 'http://www.w3.org/2000/svg'>
                    <path
                        clipRule = 'evenodd'
                        d = 'M12.6634 14.7506C12.9859 15.0789 13.5135 15.0837 13.8418 14.7612L18.0842 10.5945C18.2438 10.4378 18.3337 10.2236 18.3337 10C18.3337 9.77638 18.2438 9.56215 18.0842 9.40546L13.8418 5.23879C13.5135 4.9163 12.9859 4.92105 12.6634 5.24941C12.3409 5.57776 12.3456 6.10538 12.674 6.42787L15.4626 9.16667L2.50033 9.16667C2.04009 9.16667 1.66699 9.53976 1.66699 10C1.66699 10.4602 2.04009 10.8333 2.50033 10.8333L15.4626 10.8333L12.674 13.5721C12.3456 13.8946 12.3409 14.4222 12.6634 14.7506Z'
                        fill = '#272727'
                        fillRule = 'evenodd'
                    />
                </svg>
            </div>
        </>
    );
}

export default StepDoc;
