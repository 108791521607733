const listing = {};

listing.list = {
    companyId: {
        type:        'text',
        title:       'f10',
        width:       '5',
        typeSearch:  'companyId',
        //roles:       [ 'admin', 'manager' ],
        sort:        true,
        filter:      'SearchV2',
        sort_filter: 1,
    },
    managerId: {
        type:        'text',
        title:       'f13',
        width:       '10',
        filter:      'SearchV2',
        sort_filter: 4,
    },
    currentLines: {
        type:  'text',
        title: 'currentLines',
        width: '10',
        sort:  true,
    },
    totalLines: {
        type:  'text',
        title: 'totalLines',
        width: '10',
        sort:  true,
    },
    summ: {
        type:  'text',
        title: 'f50',
        width: '10',
        sort:  true,
    },
    price: {
        type:  'text',
        title: 'f76',
        width: '10',
        sort:  true,
    },
    price_purchase: {
        type:  'text',
        title: 'f75',
        width: '10',
        sort:  true,
    },
    summ_purchase: {
        type:  'text',
        title: 'f50_',
        width: '10',
        sort:  true,
    },
    atc_type: {
        type:   'text',
        title:  'f21',
        width:  '10',
        sort:   true,
        filter: 'Select',
    },
    createdAt: {
        type:   'date',
        title:  'f30',
        width:  '10',
        filter: 'DateRangeField',
        sort:   true,
    },
};

export { listing };
