import React from 'react';
import StylesTable from '../../../theme/styles/table.module.scss';
import StylesForm from '../../../theme/styles/form.module.scss';


function AccountRoleField(props) {
    const { fieldName, field, role, module, checked, setAccess } = props;

    return (
        <div
            className = { `${StylesTable.roleFild}` }
            data-field = { field }
            data-module = { module }
            data-role = { role }>
            <div className = { `${StylesTable.name}` }>
                { fieldName }
            </div>
            <div className = { `${StylesForm.checkboxField} ${StylesTable.checkboxField}` }>
                <div
                    className = { `${StylesForm.checkbox}
                    ${checked ? StylesForm.checked : ''}
                    ` }
                    onClick = { ()=>setAccess({
                        role:   role,
                        module: module,
                        field:  field,
                    }) }>
                    <svg
                        fill = 'none'
                        height = '9'
                        viewBox = '0 0 12 9'
                        width = '12'
                        xmlns = 'http://www.w3.org/2000/svg'>
                        <path
                            d = 'M11.3334 1L4.00002 8.33333L0.666687 5'
                            stroke = 'white'
                            strokeLinecap = 'round'
                            strokeLinejoin = 'round'
                            strokeWidth = '1.25'
                        />
                    </svg>
                </div>
            </div>
        </div>
    );
}

export default AccountRoleField;
