const listing = {};

listing.list = {
    companyId: {
        type:        'text',
        title:       'f10',
        width:       '5',
        typeSearch:  'companyId',
        //roles:       [ 'admin', 'manager' ],
        sort:        true,
        component:   'SearchV2',
        filter:      'SearchV2',
        sort_filter: 1,
    },
    managerId: {
        type:        'text',
        title:       'f13',
        width:       '10',
        filter:      'SearchV2',
        sort_filter: 4,
    },
    postman: {
        type:        'text',
        title:       'f22',
        width:       '15',
        component:   'SearchV2',
        filter:      'SearchV2',
        sort_filter: 3,
    },
    country: {
        type:  'text',
        title: 'f19',
        width: '5',
    },
    phone: {
        type:        'text',
        title:       'f31',
        width:       '10',
        sort:        true,
        typeSearch:  'phones',
        component:   'SearchV2',
        filter:      'SearchV2',
        sort_filter: 2,
    },
    date_active: {
        type:  'date',
        title: 'f38',
        width: '10',
        sort:  true,

    },
    next_pay: {
        type:      'date',
        title:     'f39',
        width:     '10',
        sort:      true,
        component: 'DateField',
    },
    price_purchase: {
        type:      'text',
        title:     'f24',
        width:     '10',
        sort:      true,
        component: 'Input',
        //roles:     [ 'admin', 'manager' ],
    },
    price_connect: {
        type:      'text',
        title:     'f40',
        width:     '10',
        sort:      true,
        component: 'Input',
        //roles:     [  'client' ],
    },
    price: {
        type:      'text',
        title:     'f41',
        width:     '10',
        // filter: 'Input',
        sort:      true,
        component: 'Input',
    },
    is_reserved: {
        type:   'checkbox',
        title:  'f28',
        width:  '9',
        sort:   true,
        filter: 'InputCheckbox',

    },

    delete: {
        type:   'delete',
        title:  'f9',
        values: [ 'rowEdit', 'delete'  ],
        width:  '1',
        //roles:  [ 'admin' ],
    },

};
listing.listing_additional = {
    companyId: {
        type:        'text',
        title:       'f10',
        width:       '5',
        typeSearch:  'companyId',
        //roles:       [ 'admin', 'manager' ],
        sort:        true,
        filter:      'SearchV2',
        sort_filter: 1,
        component:   'SearchV2',
    },
    managerId: {
        type:        'text',
        title:       'f13',
        width:       '10',
        filter:      'SearchV2',
        sort_filter: 4,
    },
    date_active: {
        type:      'date',
        title:     'f38',
        width:     '10',
        sort:      true,
        component: 'DateField',

    },
    next_pay: {
        type:      'date',
        title:     'f39',
        width:     '10',
        sort:      true,
        component: 'DateField',
    },
    lines_count: {
        type:      'text',
        title:     'f71',
        width:     '10',
        sort:      true,
        component: 'Input',

    },
    comment: {
        type:      'text',
        title:     'f42',
        width:     '10',
        sort:      true,
        component: 'Select',

    },
    price_purchase: {
        type:      'text',
        title:     'buyPrice',
        width:     '10',
        sort:      true,
        component: 'Input',
        //roles:     [ 'admin', 'manager' ],
    },
    price: {
        type:      'text',
        title:     'f43',
        width:     '10',
        sort:      true,
        component: 'Input',
    },


    delete: {
        type:   'delete',
        title:  'f9',
        values: [ 'rowEdit', 'delete'  ],
        width:  '1',
        //roles:  [ 'admin' ],

    },

};
export { listing };
