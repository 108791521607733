import React, { useEffect, useState } from 'react';
import ListConstructor from '../ListConstructor';
import { listing } from './listing';
import { setTraslate } from '../../../instruments/setTraslate';
import { useSelector } from 'react-redux';
import AccountTopTotal from '../TopTotal';

const selectState = (state) => ({
    translate: state.swapi.translate,
});

function AccountPartners(props) {
    const now = new Date();
    const start = new Date(now.setUTCDate(1)).toISOString();

    const state = useSelector(selectState);
    const translate = state.translate;
    const [ list, setList ] = useState({ ...listing.list });
    const [ filter, setFilter ] = useState({ createdAt: { from: start } });

    useEffect(()=>{
        const _list = setTraslate({ ...listing.list }, translate);
        setList(_list);
    }, [ translate, listing.list ]);

    const setCallBack = (value) => {
        setFilter(value);
    };

    return (
        <>
            <AccountTopTotal
                filter = { filter }
                type = { 'analytics' }
            />
            <ListConstructor
                { ...props }
                isDefaultFilter
                fields = { list }
                limit = { 30 }
                moduleName = { 'partners' }
                setCallBack = { setCallBack }
                sortDefault = 'calldate'
                sortOrderDefault = { false }
                type = { 'partners' }
                urlApi = { '/partners' }
            />
        </>
    );
}

export default AccountPartners;
