const listing = {};

listing.list = {
    companyId: {
        type:       'text',
        title:      'f10',
        width:      '10',
        filter:     'SearchV2',
        typeSearch: 'companyId',
    },
    managerId: {
        type:       'text',
        title:      'f13',
        width:      '10',
        filter:     'SearchV2',
        typeSearch: 'managerId',
        //roles:      [ 'admin' ],
    },
    totalCalls: {
        type:  'custom',
        title: 'st3',
        width: '10',
        sort:  true,
    },
    totalNumber: {
        type:  'custom',
        title: 'mn3',
        width: '10',
        sort:  true,
    },
    totalAtc: {
        type:  'custom',
        title: 'f12',
        width: '10',
        sort:  true,
    },
    totalSpam: {
        type:  'custom',
        title: 'f61',
        width: '10',
        sort:  true,
    },
    totalSms: {
        type:  'custom',
        title: 'f62',
        width: '10',
        sort:  true,
    },
    totalAll: {
        type:  'custom',
        title: 'f63',
        width: '10',
        sort:  true,
    },
    period: {
        type:        'text',
        title:       'f30',
        width:       '10',
        sort:        true,
        filter:      'DateRangeField',
        sort_filter: 3,
    },

};

export { listing };
