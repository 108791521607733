export const ua = {
    code:  'ua',
    title: 'Українська',
    l1:    'Вітаємо з поверненням!',
    l2:    'Введіть електронну пошту та пароль, щоб увійти до свого облікового запису.',
    l3:    'Email',
    l4:    'Пароль',
    l5:    'Користувача не знайдено',
    l6:    'Некоректний пароль',
    l7:    'Забули пароль?',
    l8:    'Логін',
    l9:    'Неправильне посилання для запрошення',
    l10:   `Вітаємо 
            Розпочніть реестрацію!`,
    l11:                   'Введіть електронну пошту та вигадайте пароль, щоб зарееструватися',
    l12:                   'Далі',
    cl1:                   'Ваш час',
    cl2:                   'Робочий час',
    hb1:                   'Баланс телефонії',
    hb2:                   'Баланс послуг',
    hb3:                   'Баланс вхідної телефонії',
    so:                    'Вийти',
    mn1:                   'Користувачі',
    mn2:                   'Компанії',
    mn3:                   'Номери',
    mn4:                   'Статистика дзвінків',
    mn5:                   'Послуги',
    mn6:                   'Постачальники',
    mn7:                   'Аналітика',
    mn8:                   'Баланс послуг',
    mn9:                   'Налаштування',
    mn10:                  'Профіт',
    mn11:                  'Тех',
    mn12:                  'ATC',
    mn13:                  'Ролі',
    mn14:                  'Аналітика Постачальників',
    mn15:                  'Партнери',
    sb1:                   'Сховати панель',
    sb2:                   'Розкрити панель',
    ad1:                   'Додати користувача',
    ad2:                   'Додати компанію',
    ad3:                   'Додати номер',
    ad4:                   'Додати постачальника',
    ad5:                   'Додати',
    ed1:                   'Редагувати користувача',
    ed2:                   'Редагувати компанію',
    ed3:                   'Редагувати номер',
    ed4:                   'Редагувати постачальника',
    bt1:                   'Повернутись назад',
    bt2:                   'Зберегти',
    bt3:                   'Пошук',
    bt4:                   'Фільтри',
    bt5:                   'Скинути фільтри',
    bt6:                   'Об’єднати',
    bt7:                   'Підключити номера компаній',
    bt8:                   'Завантажити номера постачальників',
    bt9:                   'Відключити номера',
    bt10:                  'Завантажити',
    bt11:                  'Запросити користувача',
    bt12:                  'Додаткові послуги',
    bt13:                  'Збереження',
    pg1:                   'Перейти до',
    tx1:                   'Поки що тут нічого, але це ненадовго! 😉',
    tx2:                   'Усі',
    tx3:                   'минулого місяця',
    allMinute:             'всього хвилин',
    allSumm:               'всього витрачено $',
    tx5:                   'Країна',
    tx6:                   'країни',
    tx7:                   'Активний',
    tx8:                   'Неактивний',
    tx9:                   'Помилка відповіді сервера',
    t1:                    'Статистика дзвінків',
    t2:                    'Статистика регіонів',
    t3:                    'Мої номери',
    t4:                    'Додаткові послуги',
    t5:                    'Список',
    t6:                    'Аналітика',
    st1:                   '% Дозвонів',
    st2:                   'Середня тривалість дзвінка',
    st3:                   'Дзвінки',
    st4:                   'Витрати',
    st5:                   'Інформація про сеанси.',
    st6:                   'Час у дзвінку',
    st7:                   'Витрати за геолокацією',
    st8:                   'Загальна аналітика',
    st9:                   'Діаграма',
    st10:                  'Динамика',
    st11:                  'Поповнення постачальника',
    st12:                  'Поповнення',
    st13:                  'Сума поповнення',
    st14:                  'Поповнити',
    st15:                  'Введіть суму',
    st16:                  'Поповнення балансу',
    f1:                    'Імʼя користувача',
    f2:                    'Телеграм ID',
    f3:                    'Роль',
    f4:                    'Компанії',
    f5:                    'Процент представника',
    f6:                    'Відсоток партнера',
    f6_:                   'Відсоток партнера по трафіку',
    f7:                    'Пароль',
    f8:                    'Email',
    f9:                    'Дія',
    f10:                   'Компанія',
    f11:                   'Мор',
    f12:                   'АТС',
    f13:                   'Менеджер',
    f14:                   'Кредитний лімит',
    f15:                   'Список Мор',
    f16:                   'Дата платежу',
    f17:                   'Баланс послуг/Баланс телефонії',
    f18:                   'Партнер',
    f19:                   'Країна',
    f20:                   'Телефон',
    f21:                   'Тип',
    f22:                   'Постачальник',
    f23:                   'Ціна п-ка',
    f24:                   'Абонплата п-ка',
    f25:                   'Ціна для компанії',
    f25_:                  'Сума за дзвінок',
    f26:                   'Абонплата для компанії',
    f27:                   'Видалення в постачальника',
    f28:                   'Резерв',
    f29:                   'Дата створення',
    f30:                   'Дата',
    f31:                   'Номер',
    f32:                   'Дзвінок до',
    f33:                   'Тривалість',
    f34:                   'Статус',
    f35:                   'Причина зависання',
    f36:                   'Сервер',
    profit:                'Прибуток',
    f38:                   'Дата підключення',
    f39:                   'Дата наступного списання',
    f40:                   'Вартість підключення',
    f41:                   'Абонплата за номер',
    f42:                   'Назва',
    f43:                   'Абонплата',
    f44:                   'Баланс',
    f45:                   'Списання абон. при підключенні',
    f45_:                  'Списання за підключення',
    f46:                   'Представник',
    f47:                   'Процент представника',
    f49:                   'Коментар',
    f50:                   'Сума',
    f50_:                  'Сума АТС',
    f51:                   'Послуга',
    salePrice:             'Сума транзакції',
    buyPrice:              'Сума закупки',
    survisorCompanyProfit: 'Премія менеджера',
    survisorPostmanProfit: 'Премія сюр',
    parnerCompanyProfit:   'Премія партнера',
    f53:                   'Сума закупки',
    f54:                   'Премія менеджера',
    f55:                   'Премія сюр',
    f56:                   'Премія партнера',
    f57:                   'Списано / поповнено',
    f58:                   'Залишок',
    f59:                   'Опис',
    f60:                   'На відключення',
    f61:                   'Спам чекер',
    f62:                   'СМС',
    f63:                   'Загальний профіт',
    f65:                   'Профіт по дзвінкам',
    f66:                   'Профіт по номерам',
    f67:                   'Профіт по АТС',
    f68:                   'Профіт по смс',
    f69:                   'Профіт по спам чекеру',
    f70:                   'Інформація про сеанси',
    f71:                   'Кількість ліній',
    f72:                   'Ціна за хв постачальника',
    f73:                   'Ціна за хв',
    f74:                   'Дата підключення партнера',
    f75:                   'Atc сумма закупки',
    f76:                   'Atc цена комании',
    f77:                   'Менеджери',
    in1:                   'Оберіть тип',
    in2:                   'Це допоможе нам визначити, які документи потрібно підготувати.',
    in3:                   'Фізична особа',
    in4:                   'Юридична особа',
    in5:                   'Продовжити',
    in6:                   'Введіть персональні дані',
    in7:                   'Заповніть додаткову інформацію, щоб ми могли переконатися у вашій ідентичності',
    in8:                   'Я ознайомився (-лася) та приймаю',
    in9:                   'Умови та Правила',
    in10:                  'платформи.',
    in11:                  'Завантажте документи',
    in12:                  'Для завершення процесу верифікації завантажте фото вашого паспорта з двох сторін.',
    in13:                  'Переконайтеся, що:',
    in14:                  'Фото чітке та без розмиття.',
    in15:                  'Усі кути документа видно повністю.',
    in16:                  'Текст і зображення легко прочитати.',
    in17:                  'JPEG, PNG, PDF (до 5 МБ)',
    in18:                  'Ми працюємо над вашою верифікацією!',
    in19:                  'Як тільки ми завершимо вашу верифікацію, ви отримаєте повідомлення на електронну пошту:',
    in20:                  'Зазвичай це займає до 2 годин.',
    in21:                  'Якщо у вас виникли питання, зверніться до нашого менеджера за допомогою.',
    in22:                  'Введіть ім’я компанії',
    in23:                  'Скачайте договір',
    in24:                  'Ознайомтеся з договором, завантаживши його за посиланням.',
    in25:                  'Завантажте договір',
    in26:                  'Для завершення процесу верифікації завантажте фото вашого договору.',
    in27:                  'Ім’я',
    in28:                  'Прізвище',
    in29:                  'Імʼя компанії',
    in30:                  'Угода',
    in31:                  'Введіть номер або кількість ліній АТС',
    in32:                  'Введіть номер',
    com1:                  'Поповнення на USDT',
    com2:                  'Списання з балансу телефонії',
    com3:                  'Повернення коштів за послуги',
    com4:                  'Перерахунок вартості послуг',
    com5:                  'Поповнення на WISE',
    com6:                  'кредит нота',
    com7:                  'поповнення по інвойсу №',
    cr1:                   'Списання абонплати за атс',
    cr2:                   'ліній',
    cr3:                   'Cписання абонплати за номер',
    cr4:                   'Cписання абонплати за',
    cr5:                   'ручне поповнення',
    cr6:                   'ручне списання',
    r1:                    'Модуль / Роль',
    r2:                    'Всі поля',
    r3:                    'Відображати модуль',
    r4:                    'Писати',
    r5:                    'Додати',
    r6:                    'Нова роль',
    r7:                    'Показати колонку Admin',
    m1:                    'Підтвердити видалення',
    m2:                    'Ви впевнені, що хочете видалити елемент?',
    m3:                    'Так',
    m4:                    'Ні',
    r_admin:               'Адмін',
    r_client:              'Клієнт',
    r_manager:             'Менеджер',
    r_partner:             'Партнер',
    currentLines:          'Кількість ліній в системі',
    totalLines:            'Кількість ліній з атс',
    newLine:               'Підключенно ліній',
    removeLine:            'Відключенно ліній',
    summ:                  'Сумма',
    summ_purchase:         'Сумма',
    line_atc:              'ліній атс',
    spamcheck:             'спамчекер',
    validate_numb:         'валідність номерів',
    action_confirmation:   'Підтвердження дії',
    action_confirm:        'Ви впевнені, що хочете виконати цю дію?',
    my_profile:            'Мій профіль',
    personal_info:         'Персональна інформація',
    first_name:            'Ім’я',
    last_name:             'Прізвище',
    save:                  'Зберегти',
    password:              'Пароль',
    new_password:          'Новий пароль',
    change_password:       'Змінити пароль',
    logout:                'Вихід',
    logout_platform:       'Вийти з платформи',
    confirm_action:        'Підтвердьте дію',
    save_changes_confirm:  'Ви дійсно бажаєте зберегти зміни?',
    no:                    'Ні',
    yes:                   'Так',
    success:               'Успіх',
    changes_saved:         'Зміни успішно збережено.',
};

