import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../../Api';
import Styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import { apiActions } from '../../../bus/actions';
import { removeSession } from '../../../instruments';
import { confirmAlert } from 'react-confirm-alert'; // імпорт бібліотеки

// Селектор для витягування userData зі стейта (за бажанням можна розширити).
const selectState = (state) => ({
    userData:  state.swapi.userData,
    translate: state.swapi.translate,

});

function Setting() {
    const { userData, translate } = useSelector(selectState);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logout = () => {
        removeSession();
        dispatch(apiActions.setIsLogged(false));
        dispatch(apiActions.setUserData(null));
        dispatch(apiActions.setCompany({}));
        const url = '/login';
        navigate(url);
    };
    // Локальний стейт для основних даних профілю.
    const [ formData, setFormData ] = useState({
        name:         userData?.name || '',
        surname:      userData?.surname || '',
        email:        userData?.email || '',
        myTimezone:   'utc-3',
        workTimezone: 'utc-2',
    });

    // Локальний стейт для нового пароля (міняється окремим запитом).
    const [ newPassword, setNewPassword ] = useState('');

    // Модальні вікна:
    const [ showConfirmModal, setShowConfirmModal ] = useState(false);
    const [ showSuccessModal, setShowSuccessModal ] = useState(false);

    // Обробка змін у полях ({translate.first_name}, {translate.last_name}, email, таймзони).
    const handleFormChange = (el) => {
        const { name, value } = el.target;
        setFormData((prev) => ({ ...prev, [ name ]: value }));
    };

    // Обробка зміни пароля в інпуті.
    const handlePasswordChange = (el) => {
        setNewPassword(el.target.value);
        console.log('setNewPassword');
    };

    // Перед збереженням даних — відкрити модальне вікно підтвердження
    const handleOpenConfirmModal = () => {
        setShowConfirmModal(true);
    };

    // Користувач натискає "{translate.no}" в модалці підтвердження
    const handleCloseConfirmModal = () => {
        setShowConfirmModal(false);
    };

    // Користувач підтвердив збереження
    const handleConfirmSave = async () => {
        setShowConfirmModal(false);
        try {
            const data = await api.postData({ data: { ...formData } }, '/setting/save');
            console.log('Data saved:', data);

            // Показати модалку успішного збереження
            setShowSuccessModal(true);
        } catch (error) {
            console.error('Error saving data:', error);
            // Тут можна обробити помилку, вивести повідомлення тощо.
        }
    };

    // {translate.save} час (або просто знову ж таки використати handleOpenConfirmModal, якщо треба).

    // Модалку успішного збереження закриваємо
    const handleCloseSuccessModal = () => {
        setShowSuccessModal(false);
    };
    const handlePasswordSave = async () => {
        try {
            if (newPassword) {
                await api.postData({ data: { password: newPassword } }, '/setting/password');
                setNewPassword('');
            }

            setShowSuccessModal(true);
        } catch (error) {
            console.error('Error changing password:', error);
        }
    };

    const handleConfirm = () => {
        confirmAlert({
            title:   translate.action_confirmation,
            message: translate.action_confirm,
            buttons: [
                {
                    label:     'Yes',
                    onClick:   () => handlePasswordSave(),
                    className: 'confirm',

                },
                {
                    label:     'No',
                    onClick:   () => console.log('Дію скасовано'),
                    className: 'cancel',

                },
            ],
        });
    };

    // {translate.save} новий пароль (окремим запитом).


    return (
        <div className = { Styles.settingsWrapper }>
            <h1 className = { Styles.pageTitle }>{translate.my_profile}</h1>

            {/* {translate.personal_info} */}
            <div className = { Styles.section }>
                <h2 className = { Styles.sectionTitle }>{translate.personal_info}</h2>
                <div className = { Styles.profileInfo }>
                    {/* Блок з аватаром та інпутами */}
                    <div className = { Styles.avatarBlock }>
                        <div className = { Styles.avatarCircle }>
                            {formData.name.slice(0, 1)}
                            {formData.surname.slice(0, 1)}
                        </div>
                    </div>

                    <div className = { Styles.profileFields }>
                        <div className = { Styles.field }>
                            <label
                                className = { Styles.label }
                                htmlFor = 'name'>
                                {translate.first_name}
                            </label>
                            <input
                                className = { Styles.input }
                                id = 'name'
                                name = 'name'
                                type = 'text'
                                value = { formData.name }
                                onChange = { handleFormChange }
                            />
                        </div>

                        <div className = { Styles.field }>
                            <label
                                className = { Styles.label }
                                htmlFor = 'surname'>
                                {translate.last_name}
                            </label>
                            <input
                                className = { Styles.input }
                                id = 'surname'
                                name = 'surname'
                                type = 'text'
                                value = { formData.surname }
                                onChange = { handleFormChange }
                            />
                        </div>

                        <div className = { Styles.field }>
                            <label
                                className = { Styles.label }
                                htmlFor = 'email'>
                                Email
                            </label>
                            <input
                                disabled
                                className = { Styles.input }
                                id = 'email'
                                name = 'email'
                                type = 'email'
                                value = { formData.email }
                                onChange = { handleFormChange }
                            />
                        </div>
                    </div>
                </div>

                <button
                    className = { Styles.button }
                    onClick = { handleOpenConfirmModal }>
                    {translate.save}
                </button>
            </div>

            {/* Налаштування часу */}
            {/* <div className = { Styles.section }>
                <h2 className = { Styles.sectionTitle }>Налаштування часу</h2>
                <div className = { Styles.timeSettings }>
                    <div className = { Styles.field }>
                        <label
                            className = { Styles.label }
                            htmlFor = 'myTimezone'>
                            Моя таймзона
                        </label>
                        <select
                            className = { Styles.input }
                            id = 'myTimezone'
                            name = 'myTimezone'
                            value = { formData.myTimezone }
                            onChange = { handleFormChange }>
                            <option value = 'utc-3'>UTC (-03:00) Montevideo</option>
                            <option value = 'utc-2'>UTC (-02:00) Fernando de Noronha</option>
                        </select>
                    </div>

                    <div className = { Styles.field }>
                        <label
                            className = { Styles.label }
                            htmlFor = 'workTimezone'>
                            Робоча таймзона
                        </label>
                        <select
                            className = { Styles.input }
                            id = 'workTimezone'
                            name = 'workTimezone'
                            value = { formData.workTimezone }
                            onChange = { handleFormChange }>
                            <option value = 'utc-2'>UTC (-02:00) Fernando de Noronha</option>
                            <option value = 'utc-3'>UTC (-03:00) Montevideo</option>
                        </select>
                    </div>
                </div>

                <button
                    className = { Styles.button }
                    onClick = { handleTimeSave }>
                    Змінити час
                </button>
            </div> */}

            {/* {translate.password} */}
            <div className = { Styles.section }>
                <h2 className = { Styles.sectionTitle }>{translate.password}</h2>
                <div className = { Styles.timeSettings }>
                    <div className = { Styles.field }>
                        <label
                            className = { Styles.label }
                            htmlFor = 'password'>
                            {translate.password}
                        </label>
                        <input
                            autoComplete = 'new-password'
                            className = { Styles.input }
                            id = 'password'
                            type = 'password'
                            value = { newPassword }
                            onChange = { handlePasswordChange }
                        />
                    </div>
                </div>

                <button
                    className = { Styles.button }
                    onClick = { handleConfirm }>
                    {translate.change_password}
                </button>
            </div>

            {/* {translate.logout} */}
            <div className = { Styles.section }>
                <h2 className = { Styles.sectionTitle }>{translate.logout}</h2>
                <a
                    className = { Styles.exitLink }
                    onClick = { logout }>
                    {translate.logout_platform}
                </a>
            </div>

            {/* МОДАЛЬНЕ ВІКНО ПІДТВЕРДЖЕННЯ (Confirm) */}
            {showConfirmModal && (
                <div className = { Styles.modalBackdrop }>
                    <div className = { Styles.modalContent }>
                        <h3>{translate.confirm_action}</h3>
                        <p>{translate.save_changes_confirm}</p>
                        <button onClick = { handleCloseConfirmModal }>{translate.no}</button>
                        <button onClick = { handleConfirmSave }>{translate.yes}</button>
                    </div>
                </div>
            )}

            {/* МОДАЛЬНЕ ВІКНО УСПІШНОГО ЗБЕРЕЖЕННЯ */}
            {showSuccessModal && (
                <div className = { Styles.modalBackdrop }>
                    <div className = { Styles.modalContent }>
                        <h3>{translate.success}!</h3>
                        <p>{translate.changes_saved}</p>
                        <div className = { Styles.modalButtons }>
                            <button onClick = { handleCloseSuccessModal }>Ок</button>
                        </div>

                    </div>
                </div>
            )}
        </div>
    );
}

export default Setting;
