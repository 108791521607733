// eslint-disable-line
export const book = Object.freeze({
    home:   '/',
    login:  '/login',
    invite: '/invite/:token',
    terms:  '/terms',
    admin:  {
        users:                 '/admin/users',
        usersSingle:           '/admin/users/:u_id',
        company:               '/admin/company',
        companySingle:         '/admin/company/:c_id',
        phone:                 '/admin/phone',
        postman:               '/admin/postman',
        postmanSingle:         '/admin/postman/:c_id',
        postman_analit:        '/admin/postman_analit',
        analytics:             '/admin/analytics',
        calls:                 '/admin/calls',
        service:               '/admin/service',
        serviceCompanyHistory: '/admin/serviceCompanyHistory',
        serviceCompany:        '/admin/serviceCompany',
        serviceBalance:        '/admin/serviceBalance',
        setting:               '/admin/setting',
        tech:                  '/admin/tech',
        //copyModule:               '/admin/copyModule',
        profit:                '/admin/profit/:type',
        atc:                   '/admin/atc',
        roles:                 '/admin/roles',
        partners:              '/admin/partners',


    },
});
