import React, { useEffect, useState } from 'react';
import ListConstructor from '../ListConstructor';
import { listing } from './listing';
import Import from '../Import';
import Styles from '../../../theme/styles/pageHeader.module.scss';
import { useSelector } from 'react-redux';

import Charts from './charts';
import { api } from '../../../Api';
import { setTraslate } from '../../../instruments/setTraslate';

const selectState = (state) => ({
    company:   state.swapi.company,
    userData:  state.swapi.userData,
    translate: state.swapi.translate,
});

function AccountCalls(props) {
    const now = new Date();
    const start = new Date(Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate() - 1, // мінус один день
    )).toISOString();

    const state = useSelector(selectState);
    const translate = state.translate;
    const userData = state.userData;
    const company = state.company;
    const [ list, setList ] = useState({ ...listing.list });
    const [ filter, setFilter ] = useState({ calldate: { from: start } });
    const [ tab, setTab ] = useState(0);
    const [ region, setRegion ] = useState({ ...listing.region });


    useEffect(() => {
        const _list = setTraslate({ ...listing.list }, translate);
        const _region = setTraslate({ ...listing.region }, translate);

        setList(_list);
        setRegion(_region);
    }, [ translate, listing.list ]);

    const exportFile = async () => {
        await api.postDataDownload({ filterData: filter, lang: userData.lang, tab: tab }, '/exportCalls');
    };

    const setCallBack = (value) => {
        setFilter(value);
    };

    return (
        <>
            <div className = { Styles.tabs }>
                <div
                    className = { `${Styles.tab} ${tab === 0 ? Styles.active : ''}` }
                    onClick = { () => setTab(0) }>
                    {translate.t1}
                </div>
                <div
                    className = { `${Styles.tab} ${tab === 1 ? Styles.active : ''}` }
                    onClick = { () => setTab(1) }>
                    {translate.t2}
                </div>
            </div>
            <div className = { Styles.header }>
                <div className = { Styles.title }>
                    {translate.mn4}
                </div>
                <div className = { Styles.filter }>
                    <div
                        className = { Styles.download }
                        onClick = { () => exportFile() }>
                        <img
                            alt = ''
                            src = { '/icons/download.svg' }
                        />
                        {translate.bt10}
                    </div>
                    {userData?.role === 'admin' && (
                        <Import
                            { ...props }
                            apiUrl = { `/import_mor` }
                            name = 'Import mor'
                            type = { 'phone' }
                        />
                    )}
                </div>
            </div>
            <Charts
                company_id = { company._id }
                filter = { filter }
                tab = { tab }
            />
            {tab === 0 && (
                <ListConstructor
                    { ...props }
                    isDefaultFilter
                    fields = { list }
                    filterOutside = { filter }
                    limit = { 30 }
                    moduleName = { 'calls' }
                    setCallBack = { setCallBack }
                    sortDefault = 'calldate'
                    sortOrderDefault = { false }
                    type = { 'calls' }
                    urlApi = { '/calls' }
                />
            )}
            {tab === 1 && (
                <ListConstructor
                    { ...props }
                    isDefaultFilter
                    fields = { region }
                    filterOutside = { filter }
                    limit = { 30 }
                    moduleName = { 'regionalCalls' }
                    setCallBack = { setCallBack }
                    sortDefault = 'calldate'
                    sortOrderDefault = { false }
                    type = { 'regionalCalls' }
                    urlApi = { '/regionalCalls' }
                />
            )}


        </>
    );
}

export default AccountCalls;
