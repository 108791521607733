import React, { useState, useEffect } from 'react';
import { Formik, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { apiActions } from '../../../bus/actions';
import * as Yup from 'yup';
import { api } from '../../../Api';
import { saveSession, setCompanyActive } from '../../../instruments';
import Styles from './styles.module.scss';
import StylesForm from '../../../theme/styles/form.module.scss';
import Info from '../../Icons/Info';
import { langs } from '../../../instruments/lang';
import Switcher from '../../Header/switcher';

const selectState = (state) => ({
    isLogged:  state.swapi.isLogged,
    userData:  state.swapi.userData,
    translate: state.swapi.translate,
});

const SignupSchema = Yup.object().shape({
    username: Yup.string()
        .min(2, 'errors_toShort')
        .max(30, 'errors_toLong')
        .required('errors_required'),
    password: Yup.string()
        .min(2, 'errors_toShort')
        .max(30, 'errors_toLong')
        .required('errors_required'),
});

function Login(props) {
    const { is_invite } = props;
    const [ isSend, setIsSend ] = useState(false);
    const [ error, setError ] = useState(false);
    const [ showPassw, setShowPassw ] = useState(false);
    const dispatch = useDispatch();
    const state = useSelector(selectState);
    const isLogged = state.isLogged;
    const userData = state.userData;
    const translate = state.translate;
    const navigate = useNavigate();
    const { token } = useParams();

    useEffect(() => {
        if (isLogged && userData && !token) {
            let url = '/';
            if (userData.role !== 'client') {
                url = '/admin/company';
            }
            if (userData.role === 'partner') {
                url = '/admin/partners';
            }
            if (userData.role === 'aloo') {
                url = '/admin/atc';
            }
            if (userData.role === 'survisior') {
                url = '/admin/analytics';
            }
            navigate(url);
        }
    }, [ isLogged, userData, token ]);

    const setLogin = async (values) => {
        setIsSend(true);
        setError(false);
        const data = await api.postData(values, token ? '/invite' : '/login');
        if (data && data.token) {
            saveSession(data);
            dispatch(apiActions.setUserData(data));
            if (data.company_id && data.company_id[ 0 ]?._id) {
                dispatch(apiActions.setCompany(data.company_id[ 0 ]));
                setCompanyActive(data.company_id[ 0 ]._id);
            }
            if (data.lang && langs[ data.lang ]) {
                dispatch(apiActions.setLang(data.lang));
                dispatch(apiActions.setTranslate(langs[ data.lang ]));
            }
            dispatch(apiActions.setIsLogged(true));
        } else if (data && data.error) {
            setError(data.error);
            setIsSend(false);
        } else if (data && data.invite && props.callback) {
            props.callback(data);
        }
    };


    return (
        <div className = { `${Styles.wrap}` }>
            <div className = { `${Styles.left}` }>
                <div className = { Styles.logo }>
                    <img
                        alt = ''
                        src = { '/images/logo2.svg' }
                    />
                </div>
            </div>

            <div className = { `${Styles.right}` }>
                <div className = { `${Styles.switcherWrap}` }><Switcher /></div>
                <div className = { `${Styles.formWrap}` }>
                    <div className = { `${Styles.titles}` }>
                        <div className = { `${Styles.title}` }>
                            { is_invite ? translate.l10  : translate.l1 }
                        </div>
                        <div className = { `${Styles.sub}` }>
                            { is_invite ? translate.l11  : translate.l2 }
                        </div>
                    </div>
                    <Formik
                        initialValues = {{
                            username: '',
                            password: '',
                            token:    token || '',
                        }}
                        validationSchema = { SignupSchema }
                        onSubmit = { setLogin }>
                        {(formikProps) => (
                            <form onSubmit = { formikProps.handleSubmit }>
                                <div className = { `${StylesForm.field}` }>
                                    <div className = { `${StylesForm.label}` }>
                                        { translate.l3 }
                                    </div>
                                    <Field
                                        required
                                        autoComplete = 'username'
                                        className = { `${StylesForm.input} ${error === 'not_found' ? StylesForm.error : ''}` }
                                        id = 'username'
                                        label = 'Username/Email'
                                        name = 'username'
                                        placeholder = 'm@example.com'
                                        variant = 'outlined'
                                    />
                                    {error === 'not_found' && (
                                        <div className = { `${StylesForm.error}` }>
                                            <Info className = { `${StylesForm.errorIcon}` }/>
                                            { translate.l5 }
                                        </div>
                                    )}
                                </div>
                                <div className = { `${StylesForm.field}` }>
                                    <div className = { `${StylesForm.label}` }>
                                        { translate.l4 }
                                    </div>
                                    <Field
                                        required
                                        autoComplete = 'current-password'
                                        className = { `${StylesForm.input} ${error === 'wrong_password' ? StylesForm.error : ''}` }
                                        id = 'password'
                                        label = 'Password'
                                        name = 'password'
                                        type = { showPassw ? 'text' : 'password' }
                                        variant = 'outlined'
                                    />
                                    <div
                                        className = { `${StylesForm.eye}` }
                                        onClick = { ()=>setShowPassw(!showPassw) }>
                                        <img
                                            alt = ''
                                            src = { '/icons/eye-off.svg' }
                                        />
                                    </div>
                                    {error === 'wrong_password' && (
                                        <div className = { `${StylesForm.error}` }>
                                            <Info className = { `${StylesForm.errorIcon}` }/>
                                            { translate.l6 }
                                        </div>
                                    )}
                                    {error === 'wrong_invite' && (
                                        <div className = { `${StylesForm.error}` }>
                                            <Info className = { `${StylesForm.errorIcon}` }/>
                                            { translate.l9 }
                                        </div>
                                    )}
                                </div>
                                {!is_invite ? (
                                    <div className = { `${StylesForm.link}` }>
                                        { translate.l7 }
                                    </div>
                                ) : null}

                                <div>
                                    <button
                                        className = { `${StylesForm.button}` }
                                        disabled = { isSend }
                                        type = 'submit'>
                                        { is_invite ? translate.l12  : translate.l8 }

                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}

export default Login;
