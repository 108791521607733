import React, { useEffect, useState } from 'react';
import Login from '../Login/login';
import StepRole from './StepRole';
import Switcher from '../../Header/switcher';
import Styles from './styles.module.scss';
import { useDispatch,  useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import StepFiz from './StepFiz';
import StepYur from './StepYur';
import StepDoc from './StepDoc';
import StepDocDownload from './StepDocDownload';
import StepFin from './StepFin';
import { api } from '../../../Api';
import { apiActions } from '../../../bus/actions';

const selectState = (state) => ({
    translate: state.swapi.translate,
    lang:      state.swapi.lang,
    userData:  state.swapi.userData,
    isLogged:  state.swapi.isLogged,
});

const steps_by_role = {
    1: [ 1, 2, 4, 7 ], //фіз особа
    2: [ 1, 3, 5, 6, 7 ], //юр. особа
};

function Invite(props) {
    const [ step, setStep ] = useState(props.step || 0);
    const [ data, setData ] = useState({});
    const [ percent, setPercent ] = useState(0);
    const state = useSelector(selectState);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const translate = state.translate;
    const lang = state.lang;
    const userData = state.userData;
    const isLogged = state.isLogged;

    useEffect(() => {
        if (isLogged && userData) {
            let url = '/';
            if (userData.role === 'client') {
                url = '/admin/setting';
            }
            navigate(url);
        }
        // eslint-disable-next-line
    }, [ isLogged ]);

    const sendData = async () => {
        const formData = new FormData();
        formData.append('lang', lang);
        for (const key of Object.keys(data)) {
            if (key !== 'files') {
                formData.append(key, data[ key ]);
            } else if (data.files && data.files.length > 0) {
                data.files.forEach((file) => {
                    formData.append("files", file); // "files" - ключ для файлів
                });
            }
        }
        await api.postFile(formData, '/inviteSave');
        dispatch(apiActions.setUserData({ ...userData, agreement: true }));
    };

    const loginCB = (data) => {
        setData((prev)=>{
            return { ...prev, ...data };
        });
        setStep(1);
    };

    const back = () => {
        const role = data.role;
        if (step > 1 && steps_by_role[ role ]) {
            const ind = steps_by_role[ role ].indexOf(step);
            if (ind && steps_by_role[ role ][ ind - 1 ]) {
                setStep(steps_by_role[ role ][ ind - 1 ]);
            }
        }
    };

    const roleCB = (role) => {
        setData((prev)=>{
            return { ...prev, role: role };
        });
        const step = role + 1;
        setStep(step);
    };

    const fizCB = (data) => {
        setData((prev)=>{
            return { ...prev, ...data };
        });
        setStep(4);
    };

    const yurCB = (data) => {
        setData((prev)=>{
            return { ...prev, ...data };
        });
        setStep(5);
    };

    const docCB = (data) => {
        setData((prev)=>{
            return { ...prev, ...data };
        });
        setStep(7);
    };

    const docDwCB = () => {
        setStep(6);
    };

    const docUpCB = (data) => {
        setData((prev)=>{
            return { ...prev, ...data };
        });
        setStep(7);
    };

    useEffect(()=>{
        if (step === 7) {
            sendData();
        }
    }, [ data, step ]);

    useEffect(()=>{
        const values = {
            1: 20,
            2: 50,
            3: 40,
            4: 75,
            5: 60,
            6: 80,
            7: 100,
        };
        setPercent(values[ step ] || 0);
    }, [ step ]);

    return (
        <>
            { step === 0 ? (
                <Login
                    is_invite
                    callback = { loginCB }
                />
            ) : (
                <div className = { Styles.wrap }>
                    <div className = { Styles.header }>
                        <div className = { Styles.logo }>
                            <img
                                alt = ''
                                className = { Styles.m_icon }
                                src = { '/images/logo1.svg' }
                            />
                        </div>
                        <Switcher />
                    </div>
                    <div className = { Styles.progress }>
                        <div
                            className = { Styles.back }
                            onClick = { back }>
                            <svg
                                fill = 'none'
                                height = '20'
                                viewBox = '0 0 20 20'
                                width = '20'
                                xmlns = 'http://www.w3.org/2000/svg'>
                                <path
                                    clipRule = 'evenodd'
                                    d = 'M7.33664 14.7506C7.01415 15.0789 6.48653 15.0837 6.15818 14.7612L1.91575 10.5945C1.75621 10.4378 1.66634 10.2236 1.66634 10C1.66634 9.77638 1.75621 9.56215 1.91575 9.40546L6.15818 5.23879C6.48653 4.9163 7.01415 4.92105 7.33664 5.24941C7.65913 5.57776 7.65438 6.10538 7.32602 6.42787L4.53743 9.16667L17.4997 9.16667C17.9599 9.16667 18.333 9.53976 18.333 10C18.333 10.4602 17.9599 10.8333 17.4997 10.8333L4.53743 10.8333L7.32602 13.5721C7.65438 13.8946 7.65913 14.4222 7.33664 14.7506Z'
                                    fill = '#272727'
                                    fillRule = 'evenodd'
                                />
                            </svg>

                            { translate.bt1 }
                        </div>
                        <div className = { Styles.indicator }>
                            <div className = { Styles.line }>
                                <div
                                    className = { Styles.used }
                                    style = {{ width: `${percent}%` }}
                                />
                            </div>
                            { `${percent}%`}
                        </div>
                        <div className = { Styles.empty }></div>
                    </div>
                    <div className = { `${Styles.content} ${Styles[ `step${step}` ]}` }>
                        { step === 1 && (
                            <StepRole
                                callback = { roleCB }
                            />
                        ) }
                        { step === 2 && (
                            <StepFiz
                                callback = { fizCB }
                            />
                        ) }
                        { step === 3 && (
                            <StepYur
                                callback = { yurCB }
                            />
                        ) }
                        { step === 4 && (
                            <StepDoc
                                callback = { docCB }
                                role = { 1 }
                            />
                        ) }
                        { step === 5 && (
                            <StepDocDownload
                                callback = { docDwCB }
                            />
                        ) }
                        { step === 6 && (
                            <StepDoc
                                callback = { docUpCB }
                                role = { 2 }
                            />
                        ) }
                        { step === 7 && (
                            <StepFin />
                        ) }
                    </div>
                </div>
            ) }
        </>
    );
}

export default Invite;
