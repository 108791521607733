import React from "react";

function Partners({ className }) {
    return (
        <svg
            className = { className }
            fill = 'none'
            height = '24'
            viewBox = '0 0 24 24'
            width = '24'
            xmlns = 'http://www.w3.org/2000/svg'>
            <circle
                cx = '7'
                cy = '7'
                r = '3'
                stroke = 'currentColor'
                strokeWidth = '2'
            />
            <circle
                cx = '17'
                cy = '7'
                r = '3'
                stroke = 'currentColor'
                strokeWidth = '2'
            />
            <circle
                cx = '12'
                cy = '15'
                r = '4'
                stroke = 'currentColor'
                strokeWidth = '2'
            />
            <path
                d = 'M4 19c0-3 2-5 5-5m10 5c0-3-2-5-5-5'
                stroke = 'currentColor'
                strokeLinecap = 'round'
                strokeWidth = '2'
            />
        </svg>
    );
}

export default Partners;
