// eslint-disable-line
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { apiActions } from '../../bus/actions';
import { AccountRouter } from '../Account/Router';
import history from '../Router/history';
import { getCookieLang, getUserData, getCompanyActive } from '../../instruments';

import Styles from './styles.module.scss';

import '../../theme/styles/global.css';
import { GlobalRouter } from '../Router';
import Menu from '../Header/menu';
import HeaderLine from '../Header/headerLine';
import { Agreement } from '../Account/Agreement';
import { langs } from '../../instruments/lang';

const selectState = (state) => ({
    isFetching: state.swapi.isFetching,
    isLogged:   state.swapi.isLogged,
    translate:  state.swapi.translate,
});

export const Main = () => {
    const state = useSelector(selectState);
    const isLogged = state.isLogged;
    const translate = state.translate;

    const dispatch = useDispatch();
    const [ open, setOpen ] = useState(false);

    useEffect(() => {
        try {
            const userData = getUserData();
            if (userData) {
                dispatch(apiActions.setIsLogged(true));
                dispatch(apiActions.setUserData(userData));
                if (userData.company_id && userData.company_id[ 0 ]?._id) {
                    const companyId = getCompanyActive();
                    const companyActive = userData.company_id.find((el) => el._id === companyId);
                    if (companyActive) {
                        dispatch(apiActions.setCompany(companyActive));
                    }
                }
                if (userData.lang && langs[ userData.lang ]) {
                    dispatch(apiActions.setLang(userData.lang));
                    dispatch(apiActions.setTranslate(langs[ userData.lang ]));
                }
            } else {
                const lang = getCookieLang();
                dispatch(apiActions.setIsLogged(false));
                dispatch(apiActions.setUserData(null));
                dispatch(apiActions.setCompany({}));
                if (window.location.pathname !== '/login'
                    && !window.location.pathname.includes('/invite/')
                    && !window.location.pathname.includes('/terms')
                    && !isLogged
                ) {
                    window.location.pathname = '/login';
                }
                dispatch(apiActions.setLang(lang));
                dispatch(apiActions.setTranslate(langs[ lang ]));
            }
        } catch (error) {
            //
        }
        // eslint-disable-next-line
    }, [ ]);


    return (
        <Router history = { history }>
            <div className = { `${Styles.wrap} ${isLogged ? Styles.padd : ''} ${open ? Styles.open : ''}` }>
                { isLogged && (
                    <div className = { `${Styles.sb} ${open ? Styles.open : ''}` }>
                        <div className = { Styles.menuWrap }>
                            <div className = { Styles.logo }>
                                <img
                                    alt = ''
                                    className = { Styles.m_icon }
                                    src = { open ?  '/images/logo1.svg' :  '/images/logo.svg' }
                                />
                            </div>
                            { !window.location.pathname.includes('terms') ? (
                                <Menu open = { open }/>

                            ) : null}
                        </div>
                        <div
                            className = { `${Styles.switcher} ${open ? Styles.open : ''}` }
                            onClick = { ()=>setOpen(!open) }>
                            <img
                                alt = ''
                                className = { Styles.m_icon }
                                src = { '/icons/HidePanel.svg' }
                            />
                            <span className = { Styles.m_txt }>
                                { translate.sb1 }
                            </span>
                            <span className = { Styles.tooltip }>
                                { open ? translate.sb1 : translate.sb2 }
                            </span>
                        </div>
                    </div>
                )}
                <div className = { `${Styles.content}` }>
                    { isLogged && (
                        <HeaderLine />
                    )}

                    <GlobalRouter/>
                    { isLogged && (
                        <AccountRouter/>
                    )}
                    <Agreement />

                </div>
            </div>
        </Router>
    );
};
