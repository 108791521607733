// eslint-disable-line
import { MAIN_URI } from './config';
import { getUserData, extraLogout } from '../instruments/utils';

export const api = new class Api {
    MAIN_URI = MAIN_URI;
    getData = async (url) => {
        const user = getUserData();

        const uid = user && user.token ? user.token : null;
        const uri = `${MAIN_URI}${url}${url.indexOf('?') === -1 ? '?' : '&'}uid=${uid}`;
        const response = await fetch(
            uri,
            {
                accept: 'application/json',
                method: 'GET',
            },
        );

        const result = await response.json();

        if (result && result.notAccess) {
            extraLogout();
        }

        return result;
    };

    postData = async (data, type) => {
        let url = `${this.MAIN_URI}${type}`;
        const user = getUserData();
        data.uid = user && user.token ? user.token : null;

        const response = await fetch(
            url,
            {
                method:      'POST', // *GET, POST, PUT, DELETE, etc.
                mode:        'cors', // no-cors, cors, *same-origin
                cache:       'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'omit', // include, *same-origin, omit
                headers:     {
                    Accept:         'application/json',
                    'Content-Type': 'application/json',
                    Origin:         window.location.hostname,
                    //'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data),
            },
        );
        const result = await response.json();

        if (result && result.notAccess) {
            extraLogout();
        }

        return result;
    };

    postDataDownload = async (data, type) => {
        let url = `${this.MAIN_URI}${type}`;
        const user = getUserData();
        data.uid = user && user.token ? user.token : null;
        data.lang = user && user.lang ? user.lang : 'en';
        const response = await fetch(
            url,
            {
                method:      'POST', // *GET, POST, PUT, DELETE, etc.
                mode:        'cors', // no-cors, cors, *same-origin
                cache:       'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'omit', // include, *same-origin, omit
                headers:     {
                    Accept:         'application/json',
                    'Content-Type': 'application/json',
                    Origin:         window.location.hostname,
                    //'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data),
            },
        );
        const blob = await response.blob();
        const urlDownload = window.URL.createObjectURL(blob);

        // Створюємо посилання для завантаження
        const a = document.createElement('a');
        a.href = urlDownload;
        a.download = 'data.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    postFile = async (data, type) => {
        let url = `${this.MAIN_URI}${type}`;
        const user = getUserData();
        data.uid = user && user.uid ? user.uid : null;
        const response = await fetch(
            url,
            {
                method:      'POST', // *GET, POST, PUT, DELETE, etc.
                mode:        'cors', // no-cors, cors, *same-origin
                cache:       'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'omit', // include, *same-origin, omit
                headers:     {
                    Origin: window.location.hostname,
                },
                body: data,
            },
        );
        const result = await response.json();
        if (result && result.notAccess) {
            extraLogout();
        }

        return result;
    };
}();
