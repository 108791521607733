import { listing as userListing } from '../Users/listing';
import { listing as companyListing } from '../Company/listing';
import { listing as phoneListing } from '../Phone/listing';
import { listing as callsListing } from '../Calls/listing';
import { listing as serviceCompanyListing } from '../serviceCompany/listing';

import { listing as postmanListing } from '../Postman/listing';
import { listing as analyticsListing } from '../Analytics/listing';
import { listing as serviceBalanceListing } from '../serviceBalance/listing';
import { listing as profitListing } from '../Profit/listing';
import { listing as atcListing } from '../Atc/listing';
import { listing as partnersListing } from '../Partners/listing';

const getListingFields = (list) => {
    return list ? Object.keys(list).map((key)=>{
        return {
            key:  key,
            name: list[ key ].title,
        };
    }) : null;
};

const listing = [
    {
        module: {
            key:  'users',
            name: 'mn1',
        },
        fields: getListingFields(userListing.list),
    },
    {
        module: {
            key:  'company',
            name: 'mn2',
        },
        fields: getListingFields(companyListing.list),
    },
    {
        module: {
            key:  'phone',
            name: 'mn3',
        },
        fields: getListingFields(phoneListing.list),
    },
    {
        module: {
            key:  'calls',
            name: 'mn4',
        },
        fields: getListingFields(callsListing.list),
    },
    {
        module: {
            key:  'regionalCalls',
            name: 't2',
        },
        fields: getListingFields(callsListing.region),
    },
    {
        module: {
            key:  'serviceCompany',
            name: 'mn5',
        },
        fields: getListingFields(serviceCompanyListing.list),
    },
    {
        module: {
            key:  'serviceCompanyAdditional',
            name: 't4',
        },
        fields: getListingFields(serviceCompanyListing.listing_additional),
    },
    {
        module: {
            key:  'postman',
            name: 'mn6',
        },
        fields: getListingFields(postmanListing.list),
    },
    {
        module: {
            key:  'postman_analit',
            name: 'mn14',
        },
        fields: getListingFields(postmanListing.list_analit),
    },
    {
        module: {
            key:  'analytics',
            name: 'mn7',
        },
        fields: getListingFields(analyticsListing.list),
    },
    {
        module: {
            key:  'serviceBalance',
            name: 'mn8',
        },
        fields: getListingFields(serviceBalanceListing),
    },
    {
        module: {
            key:  'profit/1',
            name: 'mn10',
        },
        fields: getListingFields(profitListing.list),
    },
    {
        module: {
            key:  'profit/3',
            name: 'st10',
        },
        fields: getListingFields(profitListing.list),
    },
    {
        module: {
            key:  'setting',
            name: 'mn9',
        },
        fields: [],
    },
    {
        module: {
            key:  'tech',
            name: 'mn11',
        },
        fields: [],
    },
    {
        module: {
            key:  'partners',
            name: 'mn15',
        },
        fields: getListingFields(partnersListing.list),
    },
    {
        module: {
            key:  'roles',
            name: 'mn13',
        },
        fields: [],
    },
    {
        module: {
            key:  'atc',
            name: 'mn12',
        },
        fields: getListingFields(atcListing.list),
    },
];

export { listing };
